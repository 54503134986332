import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import navRoutes from 'navigation/Routes';

import { PLATFORM_WEBSITE } from 'constants/common';

import { Flex, MdIcon, Text, Button, Box, Divider, chakra } from '@workshop/ui';

import { Loading } from 'components/Loading';
import { EditModal } from 'components/Common/EditModal';

interface ShareCourseModalProps {
  isOpen: boolean;
  onClose: () => void;
  courseSlug: string;
  channelHandle: string;
  isPublic: boolean;
  setIsPublic: (isPublic: boolean) => void;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  isLoading: boolean;
}

const ShareCourseModal: React.FC<ShareCourseModalProps> = ({
  isOpen,
  onClose,
  courseSlug,
  channelHandle,
  isPublic,
  setIsPublic,
  isVisible,
  setIsVisible,
  isLoading,
}) => {
  const history = useHistory();
  const relativePath = navRoutes.global.courseLanding.path(courseSlug);
  const path = `${window.location.host}${relativePath}`;
  return (
    <EditModal
      title="Share Your Course"
      isOpen={isOpen}
      onSave={() => null}
      onClose={onClose}
      onCancel={onClose}
      modalSize="xl"
      saveLabel=""
      cancelLabel="Done"
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Flex
            mb={4}
            alignItems={{ base: 'flex-start', sm: 'center' }}
            flexDirection={{ base: 'column', sm: 'row' }}
          >
            <Flex alignItems="center" flex={1} mb={{ base: 2, sm: 0 }}>
              <Text fontWeight="semibold" mr={3}>
                Course Access:
              </Text>
              <MdIcon
                name={isPublic ? 'Public' : 'GroupWork'}
                color={isPublic ? 'icon.success' : 'icon.primary'}
                mr={1.5}
              />
              <Text
                fontWeight="semibold"
                color={isPublic ? 'text.success' : 'text.primary'}
                mr={2}
              >
                {isPublic ? 'Public' : 'Classes Only'}
              </Text>
            </Flex>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsPublic(!isPublic)}
            >
              {isPublic ? 'Switch to Classes Only' : 'Make Public'}
            </Button>
          </Flex>

          <Text mb={2}>
            {`Here's where people can enroll on your classes${
              isPublic
                ? ` or publicly
            access your course content`
                : ''
            }:`}
          </Text>
          <Link to={relativePath}>
            <Box
              mb={6}
              borderRadius="md"
              backgroundColor="background.success"
              py={3}
              px={4}
              cursor="pointer"
              _hover={{ opacity: 0.75 }}
              // onClick={() => {
              //   navigator.clipboard?.writeText(fullPath);
              //   toast({
              //     title: `Copied link to clipboard`,
              //     status: 'info',
              //     duration: 1500,
              //   });
              // }}
              textAlign="center"
            >
              <Text color="text.success" fontWeight="semibold" fontSize="lg">
                {path}
              </Text>
            </Box>
          </Link>

          <Text mb={2}>
            To run your course with a private group of students,{' '}
            <chakra.span
              onClick={() =>
                history.push({
                  pathname: navRoutes.cms.classes.path(),
                  search: 'p=new',
                })
              }
              color="text.primary"
              _hover={{ textDecoration: 'underline' }}
              cursor="pointer"
              fontWeight="semibold"
            >
              Start a Class
            </chakra.span>
            .
          </Text>

          <Divider mt={6} mb={6} />
          <Box>
            <Flex
              alignItems={{ base: 'flex-start', sm: 'center' }}
              flexDirection={{ base: 'column', sm: 'row' }}
            >
              <Flex alignItems="center" flex={1} mb={{ base: 2, sm: 0 }}>
                <Text fontWeight="semibold" mr={3}>
                  Visibility:
                </Text>
                <MdIcon
                  name={isVisible ? 'Store' : 'VisibilityOff'}
                  color={isVisible ? 'icon.primary' : 'icon.muted'}
                  mr={1.5}
                />
                <Text
                  fontWeight="semibold"
                  color={isVisible ? 'text.primary' : 'text.muted'}
                  mr={2}
                >
                  {isVisible ? 'On My Channel' : 'Unlisted'}
                </Text>
              </Flex>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsVisible(!isVisible)}
              >
                {isVisible ? 'Hide From Channel' : 'Show On Channel'}
              </Button>
            </Flex>
            <Text fontSize="xs" color="text.muted">
              {isVisible
                ? `Your course is visible on ${`${PLATFORM_WEBSITE}/@${channelHandle}`}`
                : 'This course is currently not visible on your channel'}
            </Text>
          </Box>
        </>
      )}
    </EditModal>
  );
};

export default ShareCourseModal;
