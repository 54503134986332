import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps, Link, withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Element } from 'react-scroll';
import ReactCountdown from 'react-countdown';
import isEmail from 'validator/lib/isEmail';

import navRoutes from 'navigation/Routes';

import { PLATFORM } from 'constants/env';

import { courseActions, cohortActions } from 'redux/actions/learner';
import { mailActions } from 'redux/actions/common';
import { useUser } from 'redux/selectors';

import { hooks, colorUtils, analytics, getParamFromUrl } from 'utils';

import { GlobalState } from 'types';
import { MaterialColors, Cohort, Organisation } from 'types/common';
import { CourseSummary, CourseDetails, Unit } from 'types/learner';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import {
  Flex,
  Box,
  Image,
  Card,
  Text,
  Button,
  Divider,
  Grid,
  GridItem,
  Stack,
  MdIcon,
  LinkButton,
  useColorModeValue,
} from '@workshop/ui';

import { NAV_HEIGHT } from 'containers/AppHeader';
import { WORKSHOP_ORGS } from 'constants/organisation';

import { UserAvatar } from 'components/UserAvatar';
import { Loading } from 'components/Loading';
import {
  ScrollNav,
  ScheduleWidget,
  RenderHtml,
  ConfirmModal,
  LabelInput,
} from 'components/Common';
import { Footer } from 'components/Footer';

import iStep from 'assets/images/illustrations/i-step.png';
import iSkills from 'assets/images/illustrations/i-skills.png';
import iSharing from 'assets/images/illustrations/i-sharing.png';
import iCertificate from 'assets/images/illustrations/i-certificate.png';

// Routing Props
interface MatchParams {
  courseSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const Countdown = ({
  date,
  surface,
  bg,
  enrolLink,
}: {
  date: Moment;
  surface: string;
  bg: string;
  enrolLink: string;
}) => {
  return (
    <Box
      bg="background.tint3"
      borderRadius="md"
      p={6}
      position="relative"
      overflow="hidden"
    >
      <Text mb={2}>{`Next Class Starting ${date.format('Do MMMM')}`}</Text>
      {/* @ts-ignore */}
      <ReactCountdown
        date={date.valueOf()}
        renderer={({ days, hours, minutes, seconds }) => {
          const items = [
            {
              value: days,
              label: 'Days',
            },
            {
              value: hours,
              label: 'Hours',
            },
            {
              value: minutes,
              label: 'Mins',
            },
            {
              value: seconds,
              label: 'Secs',
            },
          ];
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={6}
              mb={6}
            >
              {items.map(({ value, label }) => (
                <Flex
                  key={`countdown-${label}`}
                  flexDirection="column"
                  alignItems="center"
                >
                  <Text fontSize="3xl" fontWeight="extrabold">
                    {value}
                  </Text>
                  <Text>{label}</Text>
                </Flex>
              ))}
            </Stack>
          );
        }}
      />
      <Link to={enrolLink}>
        <Button size="lg" icon="ArrowForward" iconPosition="right">
          Enroll Now
        </Button>
      </Link>
    </Box>
  );
};

const Head = ({
  course,
  nextStartDate,
  titleColor,
  subtitleColor,
  surface,
  bg,
  enrolLink,
  publicLink,
}: {
  course: CourseDetails;
  nextStartDate?: Moment;
  titleColor: string;
  subtitleColor: string;
  surface: string;
  bg: string;
  enrolLink: string;
  publicLink: string;
}) => {
  // Main image, profile picture, name, 'follow' buttons
  return (
    <Flex flex={1} flexDirection="column">
      <Card padding={0}>
        <Image
          src={course.imageLandscapeMobile}
          width="100%"
          height="auto"
          maxHeight="500px"
          objectFit="cover"
        />
      </Card>
      <Card
        mx={{ base: 4, md: 6 }}
        mt={-10}
        padding={{ base: 4, md: 6 }}
        textAlign="center"
        borderRadius="lg"
      >
        <Box flex={1}>
          <Text
            as="h1"
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
            fontWeight="extrabold"
            mt={2}
            color={titleColor}
            mb={1}
          >
            {course.title}
          </Text>
          <Link to={navRoutes.global.channel.path(course.organisation.handle)}>
            <Flex
              alignItems="center"
              justifyContent="center"
              _hover={{ opacity: 0.8 }}
            >
              <UserAvatar
                name={course.organisation.name}
                userId={course.organisation.id || 1}
                avatarPicture={course.organisation.logoDark || ''}
                size="2xs"
              />
              <Text
                fontWeight="semibold"
                fontSize="sm"
                color={subtitleColor}
                ml={3}
              >
                {course.organisation.name}
              </Text>
            </Flex>
          </Link>
          <Box mt={{ base: 6, md: 8 }} mb={{ base: 8, md: 12 }}>
            <RenderHtml html={course.summaryText} />
          </Box>

          {course.isPublic && (
            <Box
              bg="background.success"
              borderRadius="md"
              p={6}
              position="relative"
              overflow="hidden"
              mb={nextStartDate ? 6 : 0}
            >
              <Text mb={2} color="text.success">
                Public Course
              </Text>
              <Link to={publicLink}>
                <Button
                  colorScheme="green"
                  size="lg"
                  icon="ArrowForward"
                  iconPosition="right"
                >
                  Open Course
                </Button>
              </Link>
            </Box>
          )}

          {nextStartDate ? (
            <Countdown
              date={nextStartDate}
              surface={surface}
              bg={bg}
              enrolLink={enrolLink}
            />
          ) : null}
        </Box>
      </Card>
    </Flex>
  );
};

const Nav = ({
  colors,
  bg,
  surface,
  hasDescription,
  hasRatings,
  hasPreview,
  hasCohorts,
  canEnrol,
  isPublic,
  showTopPadding,
  enrolLink,
  publicLink,
  requestLink,
}: {
  colors: MaterialColors | null;
  bg: string;
  surface: string;
  hasDescription: boolean;
  hasRatings: boolean;
  hasPreview: boolean;
  hasCohorts: boolean;
  canEnrol: boolean;
  isPublic: boolean;
  showTopPadding: boolean;
  enrolLink: string;
  publicLink: string;
  requestLink?: string;
}) => {
  // In-page sticky nav

  const activeBg = useColorModeValue(
    colors?.light.primary,
    colors?.dark.primary
  );
  const activeText = useColorModeValue(
    colors?.light.onPrimary,
    colors?.dark.onPrimary
  );
  const activeHoverBg = useColorModeValue(
    colors?.light.onPrimaryContainer,
    colors?.dark.onPrimaryContainer
  );
  const inactiveText = useColorModeValue(
    colors?.light.outline,
    colors?.dark.outline
  );
  const inactiveHoverText = useColorModeValue(
    colors?.light.onSurfaceVariant,
    colors?.dark.onSurfaceVariant
  );
  const inactiveHoverBg = useColorModeValue(
    colors?.light.surfaceVariant,
    colors?.dark.surfaceVariant
  );
  const border = bg;

  const tabs: {
    slug: string;
    name: string;
    highlight?: 'blue' | 'green';
    linkTo?: string;
  }[] = [];

  if (hasDescription) {
    tabs.push({
      slug: 'about',
      name: 'About',
    });
  }

  if (!isPublic) {
    tabs.push({
      slug: 'how-it-works',
      name: 'How It Works',
    });
  }

  tabs.push({
    slug: 'curriculum',
    name: 'Curriculum',
  });

  if (hasRatings) {
    tabs.push({
      slug: 'ratings',
      name: 'Ratings',
    });
  }
  if (hasPreview) {
    tabs.push({
      slug: 'preview',
      name: 'Preview',
    });
  }
  if (hasCohorts) {
    tabs.push({
      slug: 'classes',
      name: 'Classes',
    });
  }
  if (canEnrol) {
    tabs.push({
      slug: 'enroll',
      name: 'Enroll',
      highlight: 'blue',
      linkTo: enrolLink,
    });
  } else if (isPublic) {
    tabs.push({
      slug: 'open',
      name: 'Open',
      highlight: 'green',
      linkTo: publicLink,
    });
  } else if (requestLink) {
    tabs.push({
      slug: 'enroll',
      name: 'Request',
      highlight: 'blue',
      linkTo: requestLink,
    });
  }

  return (
    <ScrollNav
      tabs={tabs}
      // @ts-ignore
      colors={
        activeBg
          ? {
              bg,
              border,
              surface,
              activeBg,
              activeHoverBg,
              activeText,
              inactiveText,
              inactiveHoverText,
              inactiveHoverBg,
            }
          : undefined
      }
      showTopPadding={showTopPadding}
      bg="background.tint3"
    />
  );
};

const About = ({ description }: { description: string }) => {
  // Intro section with website & social links
  return (
    <Flex flexDirection="column" mb={6}>
      <RenderHtml html={description} />
    </Flex>
  );
};
const steps = [
  {
    title: 'Follow Along Step by Step',
    description:
      'Build your knowledge one step at a time as each session guides you through a new skill or project.',
    image: iStep,
    imageAlt: 'Follow Along Step by Step',
  },
  {
    title: 'Learn by Doing',
    description:
      'Get hands-on experience and build a portfolio of work as you put your new skills into practice.',
    image: iSkills,
    imageAlt: 'Learn by Doing',
  },
  {
    title: 'Share Your Progress',
    description:
      'Ask questions, post photos of your work, and get feedback and support from your mentor and classmates.',
    image: iSharing,
    imageAlt: 'Share Your Progress',
  },
  {
    title: 'Earn Your Certificate',
    description:
      'Complete your course and earn a digital certificate to showcase your new skills.',
    image: iCertificate,
    imageAlt: 'Earn Your Certificate',
  },
];

const HowItWorks = ({
  mutedTextColor,
  primaryColor,
  bg,
}: {
  mutedTextColor: string;
  primaryColor: string;
  bg: string;
}) => {
  return (
    <Flex flexDirection="column" maxWidth="650px" mx="auto" my={20}>
      {steps.map((step, index) => (
        <Flex position="relative" px={{ base: 3, sm: 6, md: 0 }}>
          <Flex alignSelf="center" flexDirection="column" alignItems="center">
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
              w={10}
              h={10}
              bg={bg}
              borderWidth={2}
              borderColor={primaryColor}
              zIndex={1}
            >
              <Text fontWeight="semibold" color={primaryColor}>
                {index + 1}
              </Text>
            </Flex>
            <Box
              position="absolute"
              top={index === 0 ? '50%' : 0}
              bottom={index === steps.length - 1 ? '50%' : 0}
              w={0.5}
              bg={primaryColor}
            />
          </Flex>
          <Flex
            flex={1}
            flexDirection={{ base: 'column-reverse', md: 'row' }}
            ml={{ base: 4, md: 0 }}
            my={{ base: 4, md: 0 }}
            p={{ base: 4, md: 0 }}
            position="relative"
          >
            <Box
              display={{ base: 'block', md: 'none' }}
              bg={bg}
              // bg="background.default"
              position="absolute"
              left={0}
              top={0}
              right={0}
              bottom={0}
              opacity={0.25}
              borderRadius="lg"
            />
            <Box
              borderTopWidth="12px"
              borderBottomWidth="12px"
              borderRightWidth="12px"
              borderTopColor="transparent"
              borderBottomColor="transparent"
              borderRightColor={bg}
              // borderRightColor="background.default"
              display={{ base: 'block', md: 'none' }}
              position="absolute"
              left="-12px"
              top="calc(50% - 12px)"
              opacity={0.25}
            />
            <Box
              alignSelf="center"
              flex={1}
              pl={{ base: 0, md: 6 }}
              py={4}
              textAlign={{ base: 'center', md: 'left' }}
              position="relative"
            >
              <Text fontWeight="bold" fontSize="lg" pb={2}>
                {step.title}
              </Text>
              <Text color={mutedTextColor}>{step.description}</Text>
            </Box>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="center"
              pl={{ base: 0, md: 4 }}
              py={6}
              position="relative"
            >
              <Image
                alt={step.imageAlt}
                src={step.image}
                objectFit="contain"
                maxHeight="150px"
              />
            </Flex>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

const Curriculum = ({
  units,
  subtitleColor,
}: {
  units: Unit[];
  subtitleColor: string;
}) => {
  // Intro section with website & social links
  return (
    <Stack mb={6}>
      {units
        .filter((u) => u.unitType === 'normal')
        .map((u) => {
          const images = u.sessionImages?.map((i) => i.imageThumbnail);
          const spacing =
            images.length === 3 ? 28 : images.length === 2 ? 44 : 0;
          return (
            <Card alignItems="center" mx={{ base: -3, md: 0 }}>
              <Box
                position="relative"
                height={{ base: 'image.md', md: 'image.lg' }}
                width={{ base: 'image.md', md: 'image.lg' }}
                transform={`translateX(${(images.length - 1) * spacing}px)`}
                zIndex={0}
              >
                {images.map((img, index) => (
                  <Box
                    key={`nextClass-${index}`}
                    overflow="hidden"
                    borderRadius="md"
                    height={{ base: 'image.md', md: 'image.lg' }}
                    width={{
                      base: images.length === 1 ? 'image.lg' : 'image.md',
                      md: images.length === 1 ? 'image.xl' : 'image.lg',
                    }}
                    position="absolute"
                    left={`-${index * spacing}px`}
                    transform={`scale(${
                      index === images.length - 3
                        ? 0.6
                        : index === images.length - 2
                        ? 0.8
                        : 1
                    })`}
                    backgroundColor="background.default"
                  >
                    <Image
                      src={img}
                      height={{ base: 'image.md', md: 'image.lg' }}
                      width={{
                        base: images.length === 1 ? 'image.lg' : 'image.md',
                        md: images.length === 1 ? 'image.xl' : 'image.lg',
                      }}
                      objectFit="cover"
                      opacity={
                        index === images.length - 3
                          ? 0.3
                          : index === images.length - 2
                          ? 0.6
                          : 1
                      }
                    />
                  </Box>
                ))}
              </Box>
              <Box pl={14} flex={1}>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 'sm', sm: 'md', md: 'lg' }}
                  noOfLines={2}
                >{`${u.prefix}: ${u.title}`}</Text>
                <Text
                  fontWeight="semibold"
                  color={subtitleColor}
                  fontSize="sm"
                >{`${u.numSessions} session${
                  u.numSessions === 1 ? '' : 's'
                }`}</Text>
              </Box>
            </Card>
          );
        })}
    </Stack>
  );
};

const Ratings = ({
  totalRatings,
  percentageRating,
  fiveStarRating,
  mutedTextColor,
  primaryColor,
}: {
  totalRatings: number;
  percentageRating: number;
  fiveStarRating: number;
  mutedTextColor: string;
  primaryColor: string;
}) => {
  const stars = [];
  const numberOfFullStars = Math.floor(fiveStarRating);
  for (let i = 0; i < 5; i += 1) {
    if (i < numberOfFullStars) {
      stars.push(<MdIcon name="Star" />);
    } else if (fiveStarRating % 1 !== 0 && i < numberOfFullStars + 1) {
      stars.push(<MdIcon name="StarHalf" />);
    } else {
      stars.push(<MdIcon name="StarOutline" />);
    }
  }

  return (
    <Flex
      flexDirection="column"
      textAlign="center"
      alignItems="center"
      mt={14}
      mb={16}
    >
      <Text
        fontSize="3xl"
        fontWeight="extrabold"
      >{`${percentageRating}%`}</Text>
      <Text fontWeight="semibold">Learner Rating</Text>
      <Text
        fontSize="sm"
        color={mutedTextColor}
        mb={4}
      >{`From ${totalRatings} votes`}</Text>
      <Flex color={primaryColor} fontSize="xl">
        {stars}
      </Flex>
    </Flex>
  );
};

const Schedule = ({
  cohorts,
  channel,
}: {
  cohorts: Cohort[];
  channel: Organisation;
}) => {
  // Schedule widget of upcoming classes
  return (
    <Box mb={6}>
      <ScheduleWidget
        cohorts={cohorts}
        org={channel}
        link="checkout"
        isLoading={!channel}
      />
    </Box>
  );
};

const Preview = ({
  sessions,
  mutedTextColor,
}: {
  sessions: CourseSummary[];
  mutedTextColor: string;
}) => {
  return (
    <Grid gap={4} templateColumns="repeat(12, 1fr)" mb={6}>
      {sessions.map((c) => {
        return (
          <GridItem
            colSpan={{
              base: 12,
              sm: 6,
              md: 4,
              '2xl': 3,
            }}
          >
            <Image
              src={c.imageLandscapeThumbnail}
              height={{ base: 'auto', md: 'image.2xl' }}
              width="100%"
              maxHeight={{ base: 'image.3xl', md: 'none' }}
              minHeight={{ base: 'image.2xl', md: 'none' }}
              objectFit="cover"
              borderRadius="md"
              mb={4}
            />
            <Flex alignItems="center">
              <Text as="h3" fontWeight="semibold" flex={1}>
                {c.title}
              </Text>
              <MdIcon name="ArrowForwardIos" ml={2} color={mutedTextColor} />
            </Flex>

            {/* {c.summaryText ? (
              <Text
                color={mutedTextColor}
                fontWeight="semibold"
                fontSize="sm"
                mb={3}
                noOfLines={3}
              >
                {c.summaryText}
              </Text>
            ) : null} */}
          </GridItem>
        );
      })}
    </Grid>
  );
};

const Enrol = ({
  title,
  price,
  nextStartDate,
  titleColor,
  mutedTextColor,
  surface,
  bg,
  enrolLink,
  requestLink,
}: {
  title: string;
  price: string;
  nextStartDate?: Moment;
  titleColor: string;
  mutedTextColor: string;
  surface: string;
  bg: string;
  enrolLink: string;
  requestLink?: string;
}) => {
  // Intro section with website & social links
  return (
    <Card
      flexDirection="column"
      maxWidth="600px"
      mx="auto"
      padding={{ base: 4, md: 6 }}
      pt={{ base: 6, md: 8 }}
      textAlign="center"
      borderRadius="lg"
      mt={16}
    >
      <Text
        fontWeight="bold"
        color={titleColor}
        fontSize="2xl"
        mb={2}
      >{`Join ${title}`}</Text>
      <Text fontWeight="extrabold" fontSize="3xl">
        {`£${Number(price)}`}
      </Text>
      <Text mb={8} color={mutedTextColor}>
        14-day money back guarantee
      </Text>
      {nextStartDate ? (
        <Countdown
          date={nextStartDate}
          surface={surface}
          bg={bg}
          enrolLink={enrolLink}
        />
      ) : requestLink ? (
        <Flex justifyContent="center">
          <LinkButton to={requestLink}>Request Enrollment</LinkButton>
        </Flex>
      ) : (
        <Text color="text.muted" fontSize="sm" pb={2}>
          There are currently no spaces available for this course. Please check
          back later.
        </Text>
      )}
    </Card>
  );
};

const CourseLandingScreen: React.FC<Props> = ({
  location,
  courseSlug,
  channel,
  course,
  upcomingCohorts,
  cohorts,
  units,
}) => {
  const baseColor = channel?.id
    ? colorUtils.getRandomColor(channel.id, undefined, undefined, 'hex')
    : '';
  const [brandColor, setBrandColor] = useState(
    channel?.brandColor || baseColor
  );
  const [isRequestOpen, setIsRequestOpen] = useState(false);
  const [requestEmail, setRequestEmail] = useState('');
  const [requestName, setRequestName] = useState('');
  const [requestSending, setRequestSending] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const history = useHistory();

  const popupParam = getParamFromUrl(location, 'p');

  const user = useUser();
  const isAuthenticated = !!user?.id;

  const dispatch = useDispatch();

  const { courseLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        actions: isAuthenticated
          ? [
              () =>
                courseActions.retrieveSummary(
                  courseSlug,
                  location.pathname,
                  false
                ),
              () => cohortActions.courseList({ courseSlug }),
              // () => cohortActions.list({ fetchNextPage: true }),
            ]
          : [
              () =>
                courseActions.retrieveSummary(
                  courseSlug,
                  location.pathname,
                  false
                ),
              () => cohortActions.courseList({ courseSlug }),
            ],
      },
    },
    [isAuthenticated]
  );

  useEffect(() => {
    if (channel?.brandColor) {
      setBrandColor(channel.brandColor);
    } else if (baseColor) {
      setBrandColor(baseColor);
    }
  }, [channel?.brandColor, baseColor]);

  useEffect(() => {
    analytics.track('Viewed Course Landing Page');
  }, []);

  useEffect(() => {
    if (popupParam === 'request') {
      setIsRequestOpen(true);
    }
  }, [popupParam]);

  useEffect(() => {
    if (user?.email) {
      setRequestEmail(user.email);
      if (user.name) {
        setRequestName(user.name);
      }
    }
  }, [user?.email]);

  const colors = hooks.useColors(brandColor);
  const palette = useColorModeValue(colors?.light, colors?.dark);

  const primaryColor = palette?.primary || 'text.primary';
  const bg = palette?.primaryContainer || 'background.tint1';
  const textColor = palette?.onPrimaryContainer || 'text.default';
  const titleColor = palette?.secondary || 'text.default';
  const borderColor = 'border.default';
  const surface = palette?.surface || 'background.tint3';
  const mutedTextColor = palette?.outline || 'text.muted';

  if (
    PLATFORM !== 'steppit' ||
    WORKSHOP_ORGS.includes(channel?.id) ||
    (!courseLoading && !course)
  ) {
    history.push(navRoutes.common.home.path());
  }

  const headingProps = {
    as: 'h2' as 'h2',
    color: titleColor,
    fontWeight: 'semibold',
    mt: 3,
    mb: 6,
  };

  // TODO: Preview section
  // const previewSessions = courses.filter(
  //   (c) => c.courseType === 'session' && c.standaloneModule
  // );
  const previewSessions: CourseSummary[] = [];

  const hasDescription = Boolean(
    course && course.description && course.description !== '<p></p>'
  );
  const totalRatings =
    course && course.likes && course.dislikes
      ? course.likes + course.dislikes
      : 0;
  const percentageRating =
    course && course.likes && totalRatings > 0
      ? Math.round((course.likes / totalRatings) * 100)
      : 0;
  const hasRatings = totalRatings >= 10;
  const hasPreview = previewSessions.length > 0;
  const hasCohorts = upcomingCohorts.length > 0;
  const canEnrol = hasCohorts;
  const isPublic = !!course?.isPublic;

  const nextCohort =
    hasCohorts &&
    upcomingCohorts
      .filter((c) => moment(c.startDate).isAfter(moment()))
      .sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))[0];
  const nextStartDate = nextCohort ? moment(nextCohort.startDate) : undefined;

  const enrolLink = navRoutes.global.checkout.path(courseSlug);
  const publicLink = navRoutes.global.publicCourse.path(courseSlug);

  const requestLink = `${navRoutes.global.courseLanding.path(
    courseSlug
  )}?p=request`;

  return (
    <>
      <ConfirmModal
        body={
          requestSent ? (
            ''
          ) : (
            <>
              {`Enter your email address below to request enrollment on:\n`}
              <Text fontWeight="bold" mt={2} fontSize="lg">
                {course?.title}
              </Text>
            </>
          )
        }
        extra={
          requestSent ? (
            <Box
              textAlign="center"
              bg="background.success"
              color="text.success"
              borderRadius="sm"
              p={3}
              mb={-10}
            >
              {`Thanks for your interest! Your enrollment request has been sent to ${
                course?.organisation?.name || 'the course creator'
              } 📨`}
            </Box>
          ) : (
            <>
              <LabelInput
                isDisabled={requestSending || !course}
                label=""
                onChange={(e) => setRequestName(e.target.value)}
                placeholder="Enter your name"
                defaultValue={isAuthenticated ? user?.name : undefined}
                textAlign="center"
                fontWeight="semibold"
                mt={4}
                noMargin
              />
              <LabelInput
                isDisabled={requestSending || !course}
                label=""
                onChange={(e) => setRequestEmail(e.target.value)}
                placeholder="Enter your email address"
                defaultValue={isAuthenticated ? user?.email : undefined}
                textAlign="center"
                mt={2}
                noMargin
              />
            </>
          )
        }
        helpText={
          requestSent
            ? ''
            : `This will send a message to ${
                course?.organisation?.name || 'the course creator'
              }, notifying them of your request. It's up to them to respond to your request, and your enrollment is not guaranteed.`
        }
        btnColor="blue"
        btnLabel={requestSent ? '' : 'Send Request'}
        title="Request Enrollment"
        isOpen={isRequestOpen}
        isLoading={requestSending || !course}
        onClose={() => {
          setIsRequestOpen(false);
          history.replace(navRoutes.global.courseLanding.path(courseSlug));
        }}
        onClick={async () => {
          setRequestSending(true);
          await dispatch(
            mailActions.joinList(course.slug, {
              email: requestEmail,
              ...(requestName ? { name: requestName } : {}),
            })
          );
          setRequestSending(false);
          setRequestSent(true);
          setTimeout(() => {
            setIsRequestOpen(false);
            history.replace(navRoutes.global.courseLanding.path(courseSlug));
          }, 3000);
        }}
        hideCancel={requestSent}
        noCloseOnClick
        isDisabled={!isEmail(requestEmail)}
      />
      {courseLoading ? (
        <Loading />
      ) : (
        <>
          <Flex
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            // bg={surface}
            bg="background.tint3"
            zIndex={0}
          >
            {/* <Flex flex={1} bg={bg} opacity={0.14} /> */}
          </Flex>

          <ScreenWrapper>
            <Flex flexDirection="column" maxW="1000px" mx="auto">
              <Flex
                zIndex={4}
                color={textColor}
                mt={NAV_HEIGHT}
                pt={{ base: 0, md: 12 }}
                mb={6}
              >
                <Head
                  course={course}
                  nextStartDate={nextStartDate}
                  titleColor={titleColor}
                  subtitleColor={mutedTextColor}
                  surface={surface}
                  bg={bg}
                  enrolLink={enrolLink}
                  publicLink={publicLink}
                />
              </Flex>
              <Nav
                colors={colors}
                bg={bg}
                surface={surface}
                hasDescription={hasDescription}
                hasRatings={hasRatings}
                hasPreview={hasPreview}
                hasCohorts={hasCohorts}
                canEnrol={canEnrol}
                isPublic={isPublic}
                showTopPadding={false}
                enrolLink={enrolLink}
                publicLink={publicLink}
                requestLink={requestLink}
              />
              <Flex
                flexDirection="column"
                mx={{ base: 'defaultMargin', md: 0 }}
                mb={120}
                zIndex={1}
                color={textColor}
              >
                {hasDescription && (
                  <Element name="about">
                    <About description={course?.description || ''} />
                  </Element>
                )}

                {!isPublic && (
                  <>
                    {hasDescription ? (
                      <Divider borderColor={borderColor} />
                    ) : (
                      <Box mt={-7} />
                    )}

                    <Element name="how-it-works">
                      <HowItWorks
                        mutedTextColor={mutedTextColor}
                        primaryColor={primaryColor}
                        bg={bg}
                      />
                    </Element>
                  </>
                )}

                {hasDescription || !isPublic ? (
                  <Divider borderColor={borderColor} />
                ) : (
                  <Box mt={-7} />
                )}

                <Element name="curriculum">
                  <Text {...headingProps}>Curriculum</Text>
                  <Curriculum units={units} subtitleColor={mutedTextColor} />
                </Element>

                {hasRatings && (
                  <>
                    <Divider borderColor={borderColor} />
                    {/* <Text {...headingProps}>Ratings</Text> */}
                    <Element name="ratings">
                      <Ratings
                        totalRatings={totalRatings}
                        percentageRating={percentageRating}
                        fiveStarRating={course?.fiveStarRating || 0}
                        mutedTextColor={mutedTextColor}
                        primaryColor={primaryColor}
                      />
                    </Element>
                  </>
                )}
                {hasPreview && (
                  <>
                    <Divider borderColor={borderColor} />
                    <Element name="preview">
                      <Text {...headingProps}>Preview</Text>
                      <Preview
                        sessions={previewSessions}
                        mutedTextColor={mutedTextColor}
                      />
                    </Element>
                  </>
                )}
                {hasCohorts && (
                  <>
                    <Divider borderColor={borderColor} />
                    <Element name="classes">
                      <Text {...headingProps}>Upcoming Classes</Text>
                      <Schedule channel={channel} cohorts={upcomingCohorts} />
                    </Element>
                  </>
                )}

                <Divider borderColor={borderColor} />
                <Element name="enroll">
                  <Enrol
                    title={course?.title || ''}
                    price={course?.price || ''}
                    nextStartDate={nextStartDate}
                    titleColor={titleColor}
                    mutedTextColor={mutedTextColor}
                    surface={surface}
                    bg={bg}
                    enrolLink={enrolLink}
                    requestLink={requestLink}
                  />
                </Element>
              </Flex>
            </Flex>
          </ScreenWrapper>
          <Footer />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseSlug } = props.match.params;

  const {
    courses: {
      courses: courseState,
      cohorts,
      upcomingCohorts: upcomingCohortsState,
    },
  } = state.learner;

  // Course Data
  const course = courseState.detail[courseSlug];
  const units = (course?.units || []) as unknown as Unit[];

  const org = course?.organisation;
  const upcomingCohorts = Object.values(upcomingCohortsState).filter(
    (ch) => course && ch.course === course.slug
  );
  return {
    courseSlug,
    channel: org,
    course,
    units,
    cohorts,
    upcomingCohorts,
  };
};

const connector = connect(mapStateToProps);

export default connector(withRouter(CourseLandingScreen));
