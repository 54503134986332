import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import { CAP_CLIP_NAME } from 'constants/common';
import { PLATFORM } from 'constants/env';

import {
  Card,
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialogButton,
  Paragraph,
  Stack,
  Input,
} from '@workshop/ui';

import { Draggable, IDraggableData } from 'components/Draggable';
import {
  AddItem,
  Item,
  IAddItem,
  MultipleChoiceForm,
  MCQFormData,
  PromptForm,
  PromptFormData,
} from 'components/ListItem';
import { RichTextEditor } from 'components/RichTextEditor';
import { IconTooltip } from 'components/IconTooltip';

import { videoClipActions } from 'redux/actions/cms';

import {
  IStepListItem,
  VideoClipListState,
  IStepQuestion,
  IVideoClip,
} from 'types/cms';

import { tourIds } from 'screens/cms/SessionEdit';

import { VideoClipItem } from './VideoClipItem';
import { DraggableStep, DraggableClip } from './dataUtils';

interface Props {
  clipData: DraggableClip[];
  expandedClipData: VideoClipListState;
  stepData: DraggableStep[];
  expandedStep: string | null;
  expandedStepData: IStepListItem | null;
  questions?: IStepQuestion[];

  /** BOOLEAN CONTROLS */
  isClipLoading: boolean;
  isDraggable?: boolean;
  isDisabled?: boolean;
  isTextEditingDisabled?: boolean;
  isLoading: boolean;
  isStepListUpdating: boolean;
  isStepUpdating: boolean;
  saveDisabled: boolean;

  /** BEHAVIOUR CONTROLS */
  canAddStep?: boolean;
  canDeleteStep?: boolean;
  canAddClip?: boolean;
  canDeleteClip?: boolean;

  /** DISPLAY CONTROLS */
  shouldShowQuestions: boolean;
  shouldShowNotes?: boolean;

  /** FORM */
  stepRegister: ReturnType<typeof useForm>['register'];
  stepSetValue: ReturnType<typeof useForm>['setValue'];

  /** HANDLERS */
  handleAddClip: () => void;
  handleAddStep: (data: IAddItem) => Promise<number | null>;
  handleAddMediaFromInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    mediaType?: 'video' | 'audio'
  ) => Promise<void>;
  handleCancelUpdateStep: () => void;
  handleCreateQuestion: (args: MCQFormData, stepId: number) => Promise<any>;
  handleDeleteClip: (id: string) => void;
  handleDeleteStep: (id: string) => void;
  handleUpdateQuestion: (
    args: MCQFormData,
    stepId: number,
    questionId: number
  ) => Promise<any>;
  handleUpdateStep: (id: string) => Promise<void>;
  handleSavePrompt: (
    step: IStepListItem | undefined
  ) => (data: PromptFormData) => Promise<void>;
  handleDeletePrompt: (step: IStepListItem | undefined) => void;
  isThumbnailUpdating: (id: string) => boolean;
  onClipDragEnd: (data: IDraggableData<DraggableClip>) => void;
  onDragEnd: (data: IDraggableData<DraggableStep>) => void;
  onStepExpanded: (id: string) => Promise<void>;
  onSaveClip: (clipId: string, data: Partial<IVideoClip>) => Promise<any>;
  courseId?: number;
  sessionId?: string;
}

const StepEdit: React.FC<Props> = ({
  clipData,
  expandedClipData,
  stepData,
  expandedStep,
  expandedStepData,
  questions,
  isClipLoading = false,
  isDraggable = true,
  isDisabled = false,
  isTextEditingDisabled = false,
  isLoading = false,
  isStepListUpdating = false,
  isStepUpdating = false,
  saveDisabled,
  canAddStep = true,
  canDeleteStep = true,
  canAddClip = true,
  canDeleteClip = true,
  shouldShowQuestions = false,
  shouldShowNotes = true,
  stepRegister,
  stepSetValue,
  handleAddClip,
  handleAddStep,
  handleAddMediaFromInput,
  handleCancelUpdateStep,
  handleCreateQuestion,
  handleDeleteClip,
  handleDeleteStep,
  handleUpdateQuestion,
  handleUpdateStep,
  handleSavePrompt,
  handleDeletePrompt,
  isThumbnailUpdating = () => false,
  onClipDragEnd,
  onDragEnd,
  onStepExpanded,
  onSaveClip,
  courseId,
  sessionId,
}) => {
  const dispatch = useDispatch();

  const [droppedStep, setDroppedStep] = useState<DropResult | undefined>(
    undefined
  );
  const [droppedClip, setDroppedClip] = useState<DropResult | undefined>(
    undefined
  );
  const [draggingClip, setDraggingClip] = useState<boolean>(false);
  const [hoveringStep, setHoveringStep] = useState<string | null>(null);
  const [showSupportingNotes, setShowSupportingNotes] =
    useState<boolean>(false);
  const [showMCQs, setShowMCQs] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  // Manually register the react-hook-form field for RichTextField
  stepRegister('supportingNotes');
  useEffect(() => {
    if (showSupportingNotes) {
      setShowSupportingNotes(false);
    }
    if (showMCQs) {
      setShowMCQs(false);
    }
    if (showPrompt) {
      setShowPrompt(false);
    }
  }, [expandedStep]);

  return (
    <Card direction="column" padding={0}>
      <DragDropContext
        onDragEnd={async (result) => {
          if (result.type === 'STEP') {
            setDroppedStep(result);
          }
          if (result.type === 'CLIP') {
            setDroppedClip(result);
            setDraggingClip(false);
            if (hoveringStep) {
              const clipId = result.draggableId.replace(/^(.*)-/, '');
              const videoClip = expandedClipData[clipId];
              const newStep = stepData.find((s) => s.id === hoveringStep);
              if (videoClip && newStep) {
                onStepExpanded(`${videoClip.step}`);
                await Promise.all([
                  dispatch(
                    videoClipActions.update(parseInt(clipId), {
                      step: parseInt(newStep.id),
                    })
                  ),
                  ...Object.values(expandedClipData)
                    .filter(
                      (c) =>
                        c.step === videoClip.step && c.index > videoClip.index
                    )
                    .map((c) =>
                      dispatch(
                        videoClipActions.update(c.id, { index: c.index - 1 })
                      )
                    ),
                ]);
              }
              setHoveringStep(null);
            }
          }
        }}
        onDragStart={async (result) => {
          if (result.type === 'CLIP') {
            setDraggingClip(true);
          }
        }}
      >
        <Draggable
          dragEnabled={
            isDraggable && !expandedStep && !isLoading && !isDisabled
          }
          data={
            // During loading, render 2 empty items
            isLoading
              ? [
                  {
                    id: '0',
                    title: '',
                    label: '',
                    index: 1,
                    stepType: '',
                    clipCount: 0,
                    onClick: () => {},
                  },
                  {
                    id: '1',
                    title: '',
                    label: '',
                    index: 2,
                    stepType: '',
                    clipCount: 0,
                    onClick: () => {},
                  },
                ]
              : stepData
          }
          // @ts-ignore
          onDragEnd={onDragEnd}
          hasParentContext
          droppableType="STEP"
          lastDropped={droppedStep}
          hasDragHandle
        >
          {(step) => {
            const {
              id: stepId,
              title,
              label,
              index: stepIndex,
              stepType,
            } = step;
            return (
              <>
                <Flex
                  {...(stepIndex === 0 && stepType === 'normal'
                    ? {
                        'data-tour': tourIds.sessionOutlineSteps,
                      }
                    : {})}
                  {...(draggingClip && stepId !== expandedStep
                    ? {
                        onMouseEnter: () => setHoveringStep(stepId),
                        onMouseLeave: () => setHoveringStep(null),
                        onTouchStart: () => setHoveringStep(stepId),
                        onTouchEnd: () => setHoveringStep(null),
                      }
                    : {})}
                >
                  <Item
                    id={stepId}
                    title={title}
                    input={
                      expandedStep === stepId ? (
                        <Input
                          isDisabled={isDisabled}
                          id="title"
                          name="title"
                          ref={stepRegister({
                            required: true,
                          })}
                          defaultValue={expandedStepData?.title}
                          onClick={(e) => e.stopPropagation()}
                        />
                      ) : undefined
                    }
                    onInputSave={() => handleUpdateStep(stepId)}
                    label={label}
                    padding={3}
                    border="1px"
                    onClick={() => onStepExpanded(stepId)}
                    draggable={isDraggable}
                    dragHandleProps={step.dragHandleProps}
                    expandable
                    expanded={expandedStep === stepId}
                    isLoading={isLoading}
                    highlight={hoveringStep === stepId}
                  />
                </Flex>
                {expandedStep === stepId && (
                  <Flex flex={1} flexDirection="column">
                    {clipData.length === 0 ? (
                      <Flex p={4} justifyContent="center" color="text.muted">
                        <Paragraph margin={0}>
                          This step is currently empty
                        </Paragraph>
                      </Flex>
                    ) : (
                      <Flex
                        px={3}
                        pt={3}
                        pb={2}
                        flexDirection="column"
                        backgroundColor="background.tint3"
                      >
                        <Box>
                          <Draggable
                            data={
                              // During loading, render an empty item
                              isClipLoading ? [{ id: '0' }] : clipData
                            }
                            // @ts-ignore
                            onDragEnd={onClipDragEnd}
                            dragEnabled={!isDisabled}
                            hasParentContext
                            droppableType="CLIP"
                            lastDropped={droppedClip}
                            hasDragHandle
                          >
                            {(clip) => {
                              const { id, index } = clip;
                              const videoClip = expandedClipData[id];
                              const qrBlob =
                                courseId && sessionId
                                  ? `steppitapp://t/course/${courseId}/session/${sessionId}/step/${stepId}/bit/${id}`
                                  : '';
                              return (
                                <VideoClipItem
                                  id={id.toString()}
                                  index={index + 1}
                                  image={
                                    videoClip?.clipType === 'video'
                                      ? videoClip.videoThumbnail
                                      : videoClip?.clipType === 'image'
                                      ? videoClip.imageMobile
                                      : undefined
                                  }
                                  video={
                                    videoClip?.clipType === 'video'
                                      ? videoClip.video
                                      : videoClip?.clipType === 'audio'
                                      ? videoClip.audio
                                      : videoClip?.clipType === 'image'
                                      ? videoClip.imageMobile
                                      : undefined
                                  }
                                  mediaType={videoClip?.clipType}
                                  filename={videoClip?.originalFilename}
                                  summary={videoClip?.summary}
                                  orientation={videoClip?.orientation}
                                  isDisabled={isDisabled}
                                  isTextEditingDisabled={isTextEditingDisabled}
                                  isLoading={isClipLoading}
                                  isThumbnailUpdating={isThumbnailUpdating(
                                    id.toString()
                                  )}
                                  onUpload={handleAddMediaFromInput}
                                  onRemove={() =>
                                    handleDeleteClip(id.toString())
                                  }
                                  onSaveSummary={(summary) =>
                                    onSaveClip(id.toString(), { summary })
                                  }
                                  onSaveClip={(data) =>
                                    onSaveClip(id.toString(), data)
                                  }
                                  canDeleteClip={canDeleteClip}
                                  dragHandleProps={clip.dragHandleProps}
                                  qrBlob={qrBlob}
                                />
                              );
                            }}
                          </Draggable>
                        </Box>
                      </Flex>
                    )}
                    {!isClipLoading && !isDisabled && canAddClip && (
                      <Box mb={2} px={3} pb={3} background="background.tint3">
                        <AddItem
                          label={`Add a ${CAP_CLIP_NAME}`}
                          onSave={() => {}}
                          onClick={handleAddClip}
                          isUpdating={isClipLoading}
                          icon="HdrStrong"
                          variant="dotted"
                          button
                        />
                      </Box>
                    )}
                    <Flex p={3} flexDirection="column">
                      <Flex flexDirection="column">
                        <Stack direction="row" justifyContent="flex-end" mt={4}>
                          {shouldShowNotes &&
                            (expandedStepData?.notes || !isDisabled) && (
                              <Button
                                variant="outline"
                                borderStyle={
                                  expandedStepData?.notes || showSupportingNotes
                                    ? 'solid'
                                    : 'dashed'
                                }
                                size="sm"
                                icon="InfoOutline"
                                onClick={() =>
                                  setShowSupportingNotes(!showSupportingNotes)
                                }
                                colorScheme={
                                  showSupportingNotes ? 'neutral' : 'blue'
                                }
                              >
                                {showSupportingNotes
                                  ? 'Hide Notes'
                                  : expandedStepData?.notes
                                  ? 'Show Notes'
                                  : 'Add Notes'}
                              </Button>
                            )}
                          {PLATFORM === 'workshop' &&
                            (expandedStepData?.prompt || !isDisabled) && (
                              <Button
                                variant="outline"
                                borderStyle={
                                  expandedStepData?.prompt || showPrompt
                                    ? 'solid'
                                    : 'dashed'
                                }
                                size="sm"
                                icon="PostAdd"
                                onClick={() => setShowPrompt(!showPrompt)}
                                colorScheme={showPrompt ? 'neutral' : 'blue'}
                              >
                                {showPrompt
                                  ? 'Hide Prompt'
                                  : expandedStepData?.prompt
                                  ? 'Show Prompt'
                                  : 'Add Prompt'}
                              </Button>
                            )}
                          {PLATFORM === 'workshop' &&
                            shouldShowQuestions &&
                            ((questions && questions.length > 0) ||
                              !isDisabled) && (
                              <Button
                                variant="outline"
                                borderStyle={
                                  (questions && questions.length > 0) ||
                                  showMCQs
                                    ? 'solid'
                                    : 'dashed'
                                }
                                size="sm"
                                icon="Rule"
                                onClick={() => setShowMCQs(!showMCQs)}
                                colorScheme={showMCQs ? 'neutral' : 'blue'}
                              >
                                {showMCQs
                                  ? 'Hide Multiple-choice Questions'
                                  : questions && questions.length > 0
                                  ? 'Show Multiple-choice Questions'
                                  : 'Add Multiple-choice Questions'}
                              </Button>
                            )}
                        </Stack>

                        {shouldShowNotes && showSupportingNotes && (
                          <Box mt={4}>
                            <Box height="200px">
                              <RichTextEditor
                                isDisabled={isDisabled}
                                name="supportingNotes"
                                placeholder="Supporting Notes for this Step"
                                defaultValue={expandedStepData?.notes}
                                onChange={(name, markup) =>
                                  stepSetValue(name, markup)
                                }
                              />
                              <Box position="absolute" bottom={2} right={2}>
                                <IconTooltip tooltip="step_item_support_notes" />
                              </Box>
                            </Box>

                            <Text
                              fontSize="xs"
                              marginTop={1}
                              color="text.muted"
                            >
                              (Optional) These notes appear the step and can be
                              used to provide further explanations and links
                            </Text>
                          </Box>
                        )}
                        {showPrompt && (
                          <PromptForm
                            onSubmit={handleSavePrompt(
                              expandedStepData || undefined
                            )}
                            handleDeletePrompt={() => {
                              handleDeletePrompt(expandedStepData || undefined);
                              setShowPrompt(false);
                            }}
                            isLoading={isLoading || isStepUpdating}
                            helpText=""
                            defaultFormValues={expandedStepData?.prompt || {}}
                            isDisabled={isDisabled}
                          />
                        )}
                        {shouldShowQuestions && showMCQs ? (
                          <Box mt={4}>
                            {questions?.length && !isDisabled ? (
                              <Heading
                                fontWeight="semibold"
                                as="h3"
                                size="sm"
                                mt={6}
                              >
                                Multiple Choice Questions:
                              </Heading>
                            ) : null}
                            {!!questions?.length && (
                              <Accordion allowToggle allowMultiple my={4}>
                                {questions.map((q, idx) => (
                                  <AccordionItem>
                                    <AccordionButton>
                                      <Box flex="1" textAlign="left">
                                        {q.content}
                                      </Box>
                                      <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel p={0}>
                                      <Box
                                        backgroundColor="background.tint3"
                                        borderRadius={4}
                                        my={2}
                                        p={2}
                                      >
                                        <MultipleChoiceForm
                                          isDisabled={isDisabled}
                                          defaultValues={{
                                            question: q.content,
                                            choices: q.choices.map((c) => ({
                                              text: c.content,
                                              isCorrect: c.correct,
                                              id: c.id,
                                            })),
                                            explanation: q.explanation,
                                          }}
                                          onSubmit={async (args) =>
                                            await handleUpdateQuestion(
                                              args,
                                              parseInt(stepId),
                                              q.id
                                            )
                                          }
                                        />
                                      </Box>
                                    </AccordionPanel>
                                  </AccordionItem>
                                ))}
                              </Accordion>
                            )}
                            {!isDisabled && (
                              <Box
                                backgroundColor="background.tint3"
                                borderRadius={4}
                                my={2}
                                p={2}
                              >
                                <MultipleChoiceForm
                                  resetAfterSubmit
                                  onSubmit={async (args) =>
                                    await handleCreateQuestion(
                                      args,
                                      parseInt(stepId)
                                    )
                                  }
                                />
                              </Box>
                            )}
                          </Box>
                        ) : null}
                        {!isDisabled && (
                          <Flex
                            flex={1}
                            justifyContent="space-between"
                            alignItems="center"
                            mt={6}
                          >
                            {canDeleteStep ? (
                              <AlertDialogButton
                                alertHeader="Delete Step"
                                alertBody="Are you sure you would like to delete this step?"
                                submitBtnLabel="Delete"
                                submitBtnColor="red"
                                onSubmit={() => handleDeleteStep(stepId)}
                                onCancel={() => {}}
                                variant="outline"
                                colorScheme="red"
                                marginRight={2}
                                size="sm"
                                icon="RemoveCircle"
                              >
                                Delete Step
                              </AlertDialogButton>
                            ) : (
                              <Flex />
                            )}
                            <Box>
                              <Button
                                secondary
                                isDisabled={saveDisabled}
                                onClick={handleCancelUpdateStep}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                onClick={() => handleUpdateStep(stepId)}
                                isDisabled={saveDisabled}
                                isLoading={isStepUpdating}
                                ml={2}
                              >
                                Save
                              </Button>
                            </Box>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </>
            );
          }}
        </Draggable>
      </DragDropContext>
      {canAddStep && !isLoading && !isDisabled && (
        <Flex>
          <AddItem
            label="Add a Step"
            onSave={handleAddStep}
            isUpdating={isStepListUpdating}
            icon="Queue"
            variant="dotted"
          />
        </Flex>
      )}
    </Card>
  );
};

export default StepEdit;
