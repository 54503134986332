import React from 'react';
import {
  Box,
  Heading,
  Paragraph,
  chakra,
  TextProps,
  PropsOf,
} from '@workshop/ui';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import navRoutes from 'navigation/Routes';

import { PLATFORM } from 'constants/env';
import { PLATFORM_DISPLAY_NAME, PLATFORM_EMAIL } from 'constants/common';

const H1 = (props: TextProps) => (
  <Heading fontSize="xl" pb={3} pt={6} {...props} />
);
const H2 = (props: TextProps) => (
  <Heading fontSize="lg" pb={3} pt={6} {...props} />
);
const P = (props: TextProps) => <Paragraph pb={3} {...props} />;
const A = (props: PropsOf<typeof chakra.a>) => (
  <chakra.a color="text.primary" fontWeight="semibold" {...props} />
);
const CookiesScreen: React.FC = () => {
  return (
    <ScreenWrapper>
      <Box listStylePosition="inside">
        <P>
          <chakra.em>Last Updated 12 January, 2023</chakra.em>
        </P>
        <H1>
          <chakra.span>Introduction</chakra.span>
        </H1>
        <P>
          {PLATFORM === 'steppit' ? (
            <chakra.span>
              {`${PLATFORM_DISPLAY_NAME} is a product of Workshop Tech Solutions Ltd. ("Workshop",
              “us”, “we”, or “our”). We use cookies on steppit.com and
              affiliated websites (collectively the “Site”).`}
            </chakra.span>
          ) : (
            <chakra.span>
              Workshop (“us”, “we”, or “our”) uses cookies on steppit.com,
              workshop.ws, workshop.co.uk,&nbsp;leithsonline.com (
              <chakra.span>
                a partnership between Workshop Tech Solutions Ltd. and Leiths
                School of Food and Wine Ltd.
              </chakra.span>
              ) and affiliated websites (collectively the “Site”).
            </chakra.span>
          )}
        </P>
        <P>
          <chakra.span>
            Our Cookie Policy describes what cookies are, how we use cookies,
            how third-parties we partner with may use cookies on the Site, and
            your choices regarding cookies. Please read this Cookie Policy in
            conjunction with our{' '}
          </chakra.span>
          <A href={navRoutes.global.privacy.path()}>
            <chakra.span>Privacy Policy</chakra.span>
          </A>
          <chakra.span>
            , which sets out additional details on how we use personally
            identifiable information and how you can manage third-party cookie
            usage.
          </chakra.span>
        </P>
        <H2>
          <chakra.span>What are cookies?</chakra.span>
        </H2>
        <P>
          <chakra.span>
            Cookies are small pieces of text sent by your web browser by a
            website you visit. A cookie file is stored in your web browser and
            allows the Site or a third-party to recognise you and make your next
            visit easier and the Site more useful to you. Essentially, cookies
            are a user’s identification card for the Workshop servers. Web
            beacons are small graphic files linked to our servers that allow us
            to track your use of our Site and related functionalities. Cookies
            and web beacons allow Workshop to serve you better and more
            efficiently, and to personalise your experience on our Site.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            We use both session cookies, which expire after a short time or when
            you close your browser, and persistent cookies, which remain stored
            in your browser for a set period of time. We use session cookies to
            identify you during a single browsing session, like when you log
            into Workshop. We use persistent cookies where we need to identify
            you over a longer period, like when you request that we keep you
            signed in.
          </chakra.span>
        </P>
        <H2>
          <chakra.span>How we use cookies and similar technologies</chakra.span>
        </H2>
        <P>
          <chakra.span>
            When you use and access the Site, we may place a number of cookies
            on your device.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Workshop uses or may use cookies and/or web beacons to help us
            determine and identify repeat visitors, the type of content and
            sites to which a user of our Site links, the length of time each
            user spends at any particular area of our Site, and the specific
            functionalities that users choose to use. To the extent that cookie
            data constitutes personally identifiable information, we process
            such data on the basis of your consent.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            We use both session and persistent cookies on the Site and we use
            different types of cookies to run the Site:
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.strong>Essential cookies:</chakra.strong>
            <chakra.span>
              {' '}
              necessary for the operation of the Site. We may use essential
              cookies to authenticate users, prevent fraudulent use of user
              accounts, or offer Site features or sync course progress between
              devices.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <chakra.ul>
          <chakra.li>
            <chakra.strong>Analytical/performance cookies:</chakra.strong>
            <chakra.span>
              {' '}
              allow us to recognise you when you return to the Site. This
              enables us to personalise our content for you, greet you by name,
              and remember your preferences (for example, your choice of
              language or region).
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <chakra.ul>
          <chakra.li>
            <chakra.strong>Targeting cookies:</chakra.strong>
            <chakra.span>
              {' '}
              record your visit to the Site, the pages you have visited, and the
              links you have followed. We will use this information to make the
              Site more relevant to your interests. We may also share this
              information with third parties for this purpose.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <P>
          <chakra.span>
            When using mobile applications, you may also receive tailored in-app
            advertisements. Apple iOS, Android OS, and Microsoft Windows each
            provide its own instructions on how to control in-app tailored
            advertising. For other devices and operating systems, you should
            review your privacy settings or contact your platform operator.
          </chakra.span>
        </P>
        <H2>
          <chakra.span>Third-party cookies</chakra.span>
        </H2>
        <P>
          <chakra.span>
            In addition to our own cookies, we may also use various third-party
            cookies to report usage statistics of the Site and refine marketing
            efforts.
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            Tracking cookies:
            <chakra.span>
              {' '}
              follow on-site behaviour and tie it to other metrics allowing
              better understanding of usage habits.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <chakra.ul>
          <chakra.li>
            Optimisation cookies:
            <chakra.span>
              {' '}
              allow real-time tracking of user conversion from different
              marketing channels to evaluate their effectiveness.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <chakra.ul>
          <chakra.li>
            Partner cookies:
            <chakra.span>
              {' '}
              provide marketing conversion metrics to our partners so they can
              optimise their paid marketing efforts.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <H2>
          <chakra.span>What are my privacy options?</chakra.span>
        </H2>
        <P>
          <chakra.span>
            You have a number of options to control or limit how we and our
            partners use cookies:
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.span>
              Most browsers automatically accept cookies, but you can change
              your browser settings to decline cookies by consulting your
              browser’s support articles. If you decide to decline cookies,
              please note that you may not be able to sign in, customise, or use
              some interactive features in the Services.
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Flash cookies operate differently than browser cookies, so your
              browser’s cookie-management tools may not remove them. To learn
              more about how to manage Flash cookies, see Adobe’s{' '}
            </chakra.span>
            <A href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html">
              <chakra.span>article on managing flash cookies</chakra.span>
            </A>
            <chakra.span> and </chakra.span>
            <A href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">
              <chakra.span>Website Storage Settings panel</chakra.span>
            </A>
            <chakra.span>.</chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              To get information and control cookies used for tailored
              advertising from participating companies, see the consumer opt-out
              pages for the{' '}
            </chakra.span>
            <A href="https://www.networkadvertising.org/choices">
              <chakra.span>Network Advertising Initiative</chakra.span>
            </A>
            <chakra.span> and </chakra.span>
            <A href="http://www.aboutads.info/choices/">
              <chakra.span>Digital Advertising Alliance</chakra.span>
            </A>
            <chakra.span>
              , or if you’re located in the European Union, visit the{' '}
            </chakra.span>
            <A href="http://www.youronlinechoices.eu/">
              <chakra.span>Your Online Choices site</chakra.span>
            </A>
            <chakra.span>
              . To opt out of Google Analytics’ display advertising or customise
              Google Display Network ads, visit the{' '}
            </chakra.span>
            <A href="https://www.google.com/settings/ads">
              <chakra.span>Google Ads Settings page</chakra.span>
            </A>
            <chakra.span>.</chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              For general information about targeting cookies and how to disable
              them, visit{' '}
            </chakra.span>
            <A href="http://www.allaboutcookies.org">
              <chakra.span>www.allaboutcookies.org</chakra.span>
            </A>
            <chakra.span>.</chakra.span>
          </chakra.li>
        </chakra.ul>
        <H1>
          <chakra.span>How to contact us</chakra.span>
        </H1>
        <P>
          <chakra.span>
            From time to time, we may update this Cookie Policy. If we do, we’ll
            notify you by posting the policy on our site with a new effective
            date. If we make any material changes, we’ll take reasonable steps
            to notify you in advance of the planned change.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            If you have any questions about our use of cookies, please email us
            at{' '}
          </chakra.span>
          <A href={`mailto:privacy${PLATFORM_EMAIL}`}>
            <chakra.span>{`privacy${PLATFORM_EMAIL}`}</chakra.span>
          </A>
          <chakra.span>.</chakra.span>
        </P>
        <P></P>
      </Box>
    </ScreenWrapper>
  );
};

export default CookiesScreen;
