import * as Amp from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import slugify from 'slugify';
import crypto from 'crypto';

import { PLATFORM } from 'constants/env';

const AMP_API_KEY = process.env.REACT_APP_AMP_API_KEY;
const GA_API_KEY = process.env.REACT_APP_GA_API_KEY;

export const init = (userId?: number) => {
  if (!AMP_API_KEY) return null;
  const cookieChoice = localStorage.getItem('cookies');
  try {
    Amp.init(AMP_API_KEY, userId ? `${userId}` : undefined, {
      disableCookies:
        cookieChoice !== 'accept' && cookieChoice !== 'analyticsOnly',
      minIdLength: 1,
    });
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('Amplitude not installed');
  }
  if (!GA_API_KEY) return null;
  try {
    ReactGA.initialize(GA_API_KEY, {
      gaOptions: {
        ...(userId ? { userId } : {}),
        send_page_view: false,
      },
    });
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('GA not installed');
  }
};

export const pageView = (
  page: string,
  pageProperties: { [key: string]: string | boolean }
) => {
  if (!GA_API_KEY) return null;
  try {
    ReactGA.send({
      hitType: 'pageview',
      title: page,
      ...pageProperties,
    });
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('GA4 not installed');
    return null;
  }
};

export const track = async (
  eventName: string,
  eventProperties?: { [key: string]: string | boolean | number }
) => {
  if (!AMP_API_KEY) return null;
  try {
    await Amp.track(eventName, eventProperties).promise;
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('Amplitude not installed');
  }
  try {
    // @ts-ignore
    if (window._dcq) {
      // @ts-ignore
      window._dcq.push(['track', eventName, eventProperties]);
    }
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('Drip not installed');
  }
  if (!GA_API_KEY) return null;
  try {
    const eventSlug = slugify(eventName).toLowerCase();
    // Only trigger GA event if it's not a pageview
    if (
      !(
        eventSlug.startsWith('viewed') &&
        eventProperties &&
        'path' in eventProperties
      )
    ) {
      ReactGA.event(eventSlug, eventProperties);
    }
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('GA4 not installed');
    return null;
  }
};

export const identify = ({
  set,
  setOnce,
  add,
  preInsert,
  postInsert,
  remove,
  prepend,
  append,
}: {
  set?: { [key: string]: string | boolean };
  setOnce?: { [key: string]: string };
  add?: { [key: string]: number };
  preInsert?: { [key: string]: string };
  postInsert?: { [key: string]: string };
  remove?: { [key: string]: string };
  prepend?: { [key: string]: string };
  append?: { [key: string]: string };
}) => {
  if (!AMP_API_KEY) return null;
  const event = new Amp.Identify();
  if (set) {
    // Sets the value of a user property
    Object.keys(set).map((o) => event.set(o, set[o]));
  }
  if (setOnce) {
    // Sets the value of a user property only once
    Object.keys(setOnce).map((o) => event.setOnce(o, setOnce[o]));
  }
  if (add) {
    // Increments a user property by some numerical value.
    Object.keys(add).map((o) => event.add(o, add[o]));
  }
  if (preInsert) {
    // Pre inserts a value or values to a user property
    Object.keys(preInsert).map((o) => event.preInsert(o, preInsert[o]));
  }
  if (postInsert) {
    // Post inserts a value or values to a user property
    Object.keys(postInsert).map((o) => event.postInsert(o, postInsert[o]));
  }
  if (remove) {
    // Removes a value or values to a user property
    Object.keys(remove).map((o) => event.remove(o, remove[o]));
  }
  if (prepend) {
    // Prepend a value or values to a user property
    Object.keys(prepend).map((o) => event.prepend(o, prepend[o]));
  }
  if (append) {
    // Append a value or values to a user property array
    Object.keys(append).map((o) => event.append(o, append[o]));
  }
  // Sends identify event
  try {
    Amp.identify(event);
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('Amplitude not installed');
  }
  if (!GA_API_KEY) return null;
  if (set) {
    try {
      ReactGA._gtag('set', 'user_data', { ...set });
    } catch (err) {
      try {
        Sentry.captureException(err);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      console.warn('GA not installed');
    }
    try {
      // @ts-ignore
      if (window._dcq) {
        // @ts-ignore
        _dcq.push(['identify', { ...set }]);
      }
    } catch (err) {
      try {
        Sentry.captureException(err);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      console.warn('Drip not installed');
    }
  }
};

export const setUserId = (userId: number, userEmail?: string) => {
  if (!AMP_API_KEY) return null;
  try {
    Amp.setUserId(`${userId}`);
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('Amplitude not installed');
  }
  if (!GA_API_KEY) return null;
  try {
    ReactGA._gtag('config', GA_API_KEY, {
      user_id: userId,
      send_page_view: false,
    });
    ReactGA.set({ userId });
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('GA not installed');
  }
  localStorage.setItem('userId', `${userId}`);
  try {
    // @ts-ignore
    if (window.ire) {
      let email = '';
      if (userEmail) {
        email = crypto.createHash('sha1').update(userEmail).digest('hex');
      }
      // @ts-ignore
      window.ire('identify', {
        customerid: `${userId}`,
        customeremail: email,
      });
    }
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('Impact not installed');
  }
};

export const identifyAnonymousUser = () => {
  try {
    // @ts-ignore
    if (window.ire) {
      // @ts-ignore
      window.ire('identify', {
        customerid: '',
        customeremail: '',
      });
    }
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('Impact not installed');
  }
};

// Opt out of analytics logging
export const setOptOut = (
  optOutAnalytics: boolean,
  optOutMarketing: boolean
) => {
  if (!AMP_API_KEY) return null;
  try {
    Amp.setOptOut(optOutAnalytics);
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('Amplitude not installed');
  }
  // Set Drip cookie consent
  try {
    // @ts-ignore
    window['DripOnsite.consent.marketing'] = !optOutMarketing;
    // @ts-ignore
    window['DripOnsite.consent.analytics'] = !optOutAnalytics;
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('Drip not installed');
  }
  if (!GA_API_KEY) return null;
  if (optOutMarketing) {
    ReactGA._gtag('consent', GA_API_KEY, {
      ad_personalization: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
      wait_for_update: 500,
    });
    ReactGA._gtag('set', 'ads_data_redaction', true);
    ReactGA._gtag('set', 'url_passthrough', true);
  }
};

// Reset on log out
export const reset = () => {
  if (!AMP_API_KEY) return null;
  try {
    Amp.reset();
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('Amplitude not installed');
  }
  if (!GA_API_KEY) return null;
  try {
    ReactGA.reset();
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    console.warn('GA not installed');
  }
};

type ConversionEvent =
  | 'startedPlan'
  | 'generatedPlan'
  | 'startedProCheckout'
  | 'purchasedPro'
  | 'signedUp'
  | 'createdChannel';

// Log conversion with Ad accounts
export const logConversion = (
  event: ConversionEvent,
  properties?: { [key: string]: any }
) => {
  if (PLATFORM === 'steppit') {
    let adsEventId = '';
    switch (event) {
      case 'startedPlan': {
        adsEventId = 'QXuGCM7sqKkYENzo688p';
        break;
      }
      case 'generatedPlan': {
        adsEventId = '7QvXCJC21J4YENzo688p';
        break;
      }
      case 'startedProCheckout': {
        adsEventId = 'AYJJCJO21J4YENzo688p';
        break;
      }
      case 'purchasedPro': {
        adsEventId = 'lXKHCJa21J4YENzo688p';
        break;
      }
      case 'signedUp': {
        adsEventId = '6bONCJm21J4YENzo688p';
        break;
      }
      case 'createdChannel': {
        adsEventId = '6MK5CJy21J4YENzo688p';
        break;
      }
    }
    // @ts-ignore
    if (window.gtag) {
      try {
        // @ts-ignore
        window.gtag('event', 'conversion', {
          send_to: `AW-11173295196/${adsEventId}`,
          ...(event === 'purchasedPro' ? { transaction_id: '' } : {}),
          ...(properties ? properties : {}),
        });
      } catch (err) {
        try {
          Sentry.captureException(err);
        } catch (e) {
          console.warn('Sentry not installed');
        }
        console.warn('Gtag not installed');
      }
    }
  }
};
