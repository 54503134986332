import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FaGem } from 'react-icons/fa';
import {
  Flex,
  Box,
  Grid,
  Text,
  HStack,
  Divider,
  List,
  ListIcon,
  ListItem,
  Button,
  useColorModeValue,
} from '@workshop/ui';
import { CheckCircleIcon } from '@chakra-ui/icons';

import { GlobalState } from 'types';

import { PRO_TIER_COLORS } from 'constants/organisation';

type Price = {
  name: string;
  popular?: boolean;
  info: string;
  features: string[];
  featuresIntro: string;
  tier: 0 | 1 | 2 | 3;
  payMonthlyPriceGBP?: string;
  payMonthlyPriceEUR?: string;
  payMonthlyPriceUSD?: string;
  payYearlyPriceGBP?: string;
  payYearlyPriceEUR?: string;
  payYearlyPriceUSD?: string;
};

export const prices: Price[] = [
  {
    name: 'Starter',
    payMonthlyPriceGBP: '£30',
    payMonthlyPriceEUR: '€35',
    payMonthlyPriceUSD: '$30',
    payYearlyPriceGBP: '£25',
    payYearlyPriceEUR: '€29',
    payYearlyPriceUSD: '$25',
    featuresIntro: 'All Basic features, plus:',
    features: [
      'Unlimited units on your courses',
      'Fully personalized AI assistant with 2,000 AI credits per month + 1,000 bonus credits on signup',
      'Unlimited course sales via Steppit with 80% rev share',
      // 'Up to 10 course enrolments per month via invites or integrations',
      'Up to 10 course enrolments per month via invites',
      'Up to 5 channel team members',
    ],
    info: 'Start building your perfect course and enrolling your first learners.',
    tier: 1,
  },
  {
    name: 'Growth',
    payMonthlyPriceGBP: '£80',
    payMonthlyPriceEUR: '€90',
    payMonthlyPriceUSD: '$80',
    payYearlyPriceGBP: '£67',
    payYearlyPriceEUR: '€75',
    payYearlyPriceUSD: '$67',
    featuresIntro: 'All Starter plan features, plus:',
    features: [
      '5,000 AI credits per month for your personalized AI assistant + 1,000 bonus credits on signup',
      '85% rev share of course sales via Steppit',
      // 'Up to 30 course enrolments per month via invites or integrations',
      'Up to 30 course enrolments per month via invites',
      'Up to 10 channel team members',
      'High priority customer support',
    ],
    info: 'Generate more content and expand the reach of your courses.',
    tier: 2,
    popular: true,
  },
  {
    name: 'Scale',
    payMonthlyPriceGBP: '£140',
    payMonthlyPriceEUR: '€160',
    payMonthlyPriceUSD: '$140',
    payYearlyPriceGBP: '£117',
    payYearlyPriceEUR: '€134',
    payYearlyPriceUSD: '$117',
    featuresIntro: 'All Growth plan features, plus:',
    features: [
      '10,000 AI credits per month for your personalized AI assistant + 1,000 bonus credits on signup',
      '90% rev share of course sales via Steppit',
      // 'Up to 60 course enrolments per month via invites or integrations',
      'Up to 60 course enrolments per month via invites',
      'Up to 20 channel team members',
      'Top priority customer support',
    ],
    info: 'Scale up your services with more learners and team members.',
    tier: 3,
  },
];

interface PricingBoxProps extends Price {
  planType: 'monthly' | 'yearly';
  proTrialAvailable?: boolean;
  onClickCta?: (
    planType: 'monthly' | 'yearly',
    tier: 0 | 1 | 2 | 3
  ) => Promise<void>;
}

const PricingBox = ({
  popular,
  name,
  info = '',
  features = [],
  featuresIntro = '',
  tier = 0,
  payMonthlyPriceGBP,
  payMonthlyPriceEUR,
  payMonthlyPriceUSD,
  payYearlyPriceGBP,
  payYearlyPriceEUR,
  payYearlyPriceUSD,
  planType,
  proTrialAvailable,
  onClickCta,
}: PricingBoxProps) => {
  const tierColor = PRO_TIER_COLORS[tier];
  const mainTierColor = useColorModeValue(
    `${tierColor}.550`,
    `${tierColor}.200`
  );
  const bgTierColor = useColorModeValue(`${tierColor}.50`, `${tierColor}.700`);
  const borderTierColor = useColorModeValue(
    `${tierColor}.100`,
    `${tierColor}.200`
  );

  const location = useSelector(
    (state: GlobalState) => state.background.location
  );

  const currency = location?.currency || 'USD';

  const payMonthlyPrice =
    currency === 'GBP'
      ? payMonthlyPriceGBP
      : currency === 'EUR'
      ? payMonthlyPriceEUR
      : payMonthlyPriceUSD;

  const payYearlyPrice =
    currency === 'GBP'
      ? payYearlyPriceGBP
      : currency === 'EUR'
      ? payYearlyPriceEUR
      : payYearlyPriceUSD;

  const price = planType === 'monthly' ? payMonthlyPrice : payYearlyPrice;
  return (
    <Flex
      flexDirection="column"
      p={4}
      position="relative"
      borderWidth={1}
      {...(popular
        ? {
            bg: 'background.tint1',
            borderColor: 'border.muted',
            borderRadius: 'lg',
          }
        : {
            borderColor: 'transparent',
          })}
    >
      {popular && (
        <Box
          position="absolute"
          top={4}
          right={4}
          paddingX={2}
          paddingY={1}
          bg="background.tint2"
          color="text.muted"
          fontSize="xs"
          borderRadius="full"
        >
          <Text>Most popular</Text>
        </Box>
      )}
      <Box mb={3}>
        <Flex
          boxSize="image.xs"
          alignItems="center"
          justifyContent="center"
          borderRadius="full"
          bg={bgTierColor}
          color={mainTierColor}
          fontSize="xl"
        >
          <FaGem />
        </Flex>
      </Box>
      <Text color={mainTierColor} fontSize="3xl" fontWeight="bold" mb={2}>
        {name}
      </Text>
      <Text fontSize="sm" color="text.muted" mb={4}>
        {info}
      </Text>
      <HStack mb={2}>
        <Text fontSize="4xl" fontWeight="semibold">
          {price ?? 'Free'}
        </Text>
        {price && (
          <Box
            as="span"
            color="text.muted"
            fontSize="sm"
            whiteSpace="break-spaces"
            lineHeight={1.1}
          >
            {`per\nmonth`}
          </Box>
        )}
      </HStack>
      {planType === 'yearly' && (
        <Box
          as="span"
          color="text.muted"
          fontSize="xs"
          whiteSpace="break-spaces"
          lineHeight={1.1}
          mb={2}
          mt={-1}
        >
          {`Billed yearly`}
        </Box>
      )}

      <Divider borderColor={borderTierColor} mb={6} />
      <Text color="text.muted" fontSize="sm" fontWeight="semibold" mb={2}>
        {featuresIntro}
      </Text>
      <List flex={1} mb={12}>
        {features.map((feat) => (
          <ListItem
            key={Math.random()}
            fontSize="sm"
            color="text.muted"
            pb={1}
            display="flex"
          >
            <ListIcon as={CheckCircleIcon} color="common.progress" mt={1} />
            <Text>{feat}</Text>
          </ListItem>
        ))}
      </List>
      {onClickCta ? (
        <Button
          variant={popular ? 'solid' : 'outline'}
          onClick={() => onClickCta(planType, tier)}
        >
          {proTrialAvailable ? 'Start 7-day Free Trial' : 'Select Plan'}
        </Button>
      ) : null}
    </Flex>
  );
};

const PricingTable: React.FC<{
  proTrialAvailable?: boolean;
  onClickCta?: (
    planType: 'monthly' | 'yearly',
    tier: 0 | 1 | 2 | 3
  ) => Promise<void>;
}> = ({ proTrialAvailable, onClickCta }) => {
  const [planType, setPlanType] = useState<'monthly' | 'yearly'>('monthly');
  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      <HStack
        spacing={1}
        border="1px solid"
        borderColor="border.muted"
        bg="background.tint1"
        borderRadius="full"
        mb={6}
        p={1}
      >
        <Button
          variant={planType === 'monthly' ? 'solid' : 'ghost'}
          size="sm"
          onClick={() => setPlanType('monthly')}
        >
          Monthly
        </Button>
        <Button
          variant={planType === 'yearly' ? 'solid' : 'ghost'}
          size="sm"
          onClick={() => setPlanType('yearly')}
        >
          Yearly
        </Button>
      </HStack>
      <Grid
        w="full"
        gap={5}
        justifyContent="center"
        templateColumns={{
          base: 'inherit',
          md: 'repeat(3, 1fr)',
        }}
      >
        {prices.map((price) => (
          <PricingBox
            key={price.name}
            planType={planType}
            proTrialAvailable={proTrialAvailable}
            onClickCta={onClickCta}
            {...price}
          />
        ))}
      </Grid>
    </Flex>
  );
};

export default PricingTable;
