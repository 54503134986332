import { COHORT_TYPES, SOCIAL_TYPES } from 'constants/courses';
import { PRICING_MATRIX } from 'constants/settings';

import { Cohort } from 'types/common';
import { ModuleVideoClip, Unit } from 'types/learner';

const getCohortType = (cohort: Cohort) => {
  return COHORT_TYPES.find((c) =>
    cohort.isAnonymous
      ? c.isAnonymous
      : c.accessType === cohort.accessType && c.socialType === cohort.socialType
  );
};

const hasClassSizeLimit = (cohort: Partial<Cohort>) =>
  cohort.socialType === SOCIAL_TYPES.private && !cohort.isAnonymous;

const getMediaFromClip = (clip?: ModuleVideoClip) => {
  if (!clip) return null;
  if (clip.clipType === 'image') return clip.imageMobile;
  if (clip.clipType === 'audio') return clip.audio;
  if (clip.clipType === 'video') return clip.videoHls;
  return null;
};

const getMediaFromUnit = (unit?: Unit) => {
  if (!unit) return null;
  if (unit.unitClipType === 'image') return unit.imageMobile;
  if (unit.unitClipType === 'audio') return unit.audio;
  if (unit.unitClipType === 'video') return unit.videoHls;
  return null;
};

// Get the tier that correlates with this price (if available)
const getTierFromPrice = (value: number, currency: string = 'GBP') => {
  const valueStr = `${value}`;
  let idx = 0;
  const tiersArr = Object.values(PRICING_MATRIX);
  for (const tier of tiersArr) {
    idx++;
    // @ts-ignore
    if (tier[currency] === valueStr) {
      return idx;
    }
  }
  return undefined;
};

// Get the appropriate local price from the pricing matrix
const getPriceFromTier = (tier: string | number, currency: string = 'GBP') =>
  // @ts-ignore
  PRICING_MATRIX[tier] ? PRICING_MATRIX[tier][currency] : undefined;

export default {
  getCohortType,
  hasClassSizeLimit,
  getMediaFromClip,
  getMediaFromUnit,
  getPriceFromTier,
  getTierFromPrice,
};
