import { decamelizeKeys } from 'humps';

import { callAPI } from 'utils';
import API from 'constants/api';
import { Auth } from 'redux/actionTypes/common';
import {
  FetchTokenAction,
  RefreshTokenAction,
  DeleteTokenAction,
  ClearAuthError,
  LoginData,
  SignupData,
  ChangePasswordAction,
  FetchSocialProvidersAction,
  FetchSocialConnectionsAction,
  RemoveSocialConnectionAction,
  ResetPasswordAction,
  SocialLoginAction,
  SocialConnectAction,
  ConfirmEmailAction,
  FetchRoadmapTokenAction,
  ProviderTypes,
} from 'types/common';

export const clearAuthError: ClearAuthError = {
  type: Auth.CLEAR_AUTH_ERROR,
};

export const tokenRequest = (data: LoginData) => {
  return callAPI<FetchTokenAction>({
    types: [
      Auth.FETCH_TOKEN_REQUEST,
      Auth.FETCH_TOKEN_SUCCESS,
      Auth.FETCH_TOKEN_FAILURE,
    ],
    endpoint: API.auth.login,
    method: 'POST',
    body: data,
    meta: { toast: false },
  });
};

export const refreshTokenRequest = () => {
  return callAPI<RefreshTokenAction>({
    types: [
      Auth.REFRESH_TOKEN_REQUEST,
      Auth.REFRESH_TOKEN_SUCCESS,
      Auth.REFRESH_TOKEN_FAILURE,
    ],
    endpoint: API.auth.refreshToken,
    method: 'POST',
    meta: { toast: false },
  });
};

export const tokenDeleteRequest = () => {
  return callAPI<DeleteTokenAction>({
    types: [
      Auth.DELETE_TOKEN_REQUEST,
      Auth.DELETE_TOKEN_SUCCESS,
      Auth.DELETE_TOKEN_FAILURE,
    ],
    endpoint: API.auth.logout,
    method: 'POST',
    meta: { toast: false },
  });
};

export const socialConnect = (
  data: {
    accessToken?: string;
    idToken?: string;
  },
  provider: ProviderTypes
) => {
  const upperCaseProvider = `${provider
    .charAt(0)
    .toUpperCase()}${provider.slice(1)}`;
  return callAPI<SocialConnectAction>({
    types: [
      Auth.SOCIAL_CONNECT_REQUEST,
      Auth.SOCIAL_CONNECT_SUCCESS,
      Auth.SOCIAL_CONNECT_FAILURE,
    ],
    endpoint: API.auth.socialConnect(provider),
    method: 'POST',
    body: decamelizeKeys(data),
    meta: {
      toast: {
        success: `${upperCaseProvider} account successfully connected`,
        error: {
          message: `Failure to Connect to ${upperCaseProvider}`,
          source: 'api',
        },
      },
    },
  });
};

export const socialLogin = (
  provider: ProviderTypes,
  data: { accessToken?: string; code?: string; idToken?: string }
) => {
  return callAPI<SocialLoginAction>({
    types: [
      Auth.SOCIAL_LOGIN_REQUEST,
      Auth.SOCIAL_LOGIN_SUCCESS,
      Auth.SOCIAL_LOGIN_FAILURE,
    ],
    endpoint: API.auth.socialLogin(provider),
    method: 'POST',
    body: decamelizeKeys(data),
    meta: {
      toast: {
        success: false,
        error: {
          message: 'There was an error signing up',
          source: 'api',
        },
      },
    },
  });
};

export const signupRequest = (data: SignupData) => {
  return callAPI<FetchTokenAction>({
    types: [Auth.SIGNUP_REQUEST, Auth.SIGNUP_SUCCESS, Auth.SIGNUP_FAILURE],
    endpoint: API.auth.signup,
    method: 'POST',
    body: decamelizeKeys(data),
    meta: { toast: false },
  });
};

export const impersonateUser = (userId: number) => {
  return callAPI<FetchTokenAction>({
    types: [
      Auth.IMPERSONATE_USER_REQUEST,
      Auth.IMPERSONATE_USER_SUCCESS,
      Auth.IMPERSONATE_USER_FAILURE,
    ],
    endpoint: API.auth.impersonate(userId),
    method: 'POST',
    meta: { toast: { success: false, error: 'Failed to impersonate user.' } },
  });
};

export const stopImpersonating = () => {
  return callAPI<FetchTokenAction>({
    types: [
      Auth.STOP_IMPERSONATE_USER_REQUEST,
      Auth.STOP_IMPERSONATE_USER_SUCCESS,
      Auth.STOP_IMPERSONATE_USER_FAILURE,
    ],
    endpoint: API.auth.stopImpersonating,
    method: 'POST',
    meta: { toast: { success: false } },
  });
};

const changePassword = (data: {
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
}) => {
  return callAPI<ChangePasswordAction>({
    types: [
      Auth.CHANGE_PASSWORD_REQUEST,
      Auth.CHANGE_PASSWORD_SUCCESS,
      Auth.CHANGE_PASSWORD_FAILURE,
    ],
    endpoint: API.auth.changePassword,
    method: 'POST',
    body: decamelizeKeys(data),
    meta: {
      toast: { success: 'Password changed.', error: true },
    },
  });
};

// This action can be used to request a password reset email
// or reset your password if you have a key following the
// password reset email link
const resetPassword = ({
  data,
  key,
  uidb36,
}: {
  data: { email: string } | { password1: string; password2: string };
  key?: string;
  uidb36?: string;
}) => {
  const formData = new FormData();

  Object.keys(data).forEach((k) => {
    const key = k as never;
    formData.append(k, data[key]);
  });

  return callAPI<ResetPasswordAction>({
    types: [
      Auth.RESET_PASSWORD_REQUEST,
      Auth.RESET_PASSWORD_SUCCESS,
      Auth.RESET_PASSWORD_FAILURE,
    ],
    endpoint: API.auth.resetPassword(key, uidb36),
    method: 'POST',
    body: formData,
    headers: key && uidb36 ? { 'X-Requested-With': 'XMLHttpRequest' } : {},
    meta: {
      toast: {
        success:
          key && uidb36
            ? false
            : 'A link to reset your password has been emailed to you',
        // TODO: Add error messages from the backend - perhaps it's best to
        // use inline errors rather than the toast for this endpoint?
        error:
          key && uidb36
            ? 'Unable to change password'
            : 'Password reset email has not been sent',
      },
    },
  });
};

const verifyPasswordResetKey = (key: string, uidb36: string) => {
  return callAPI<ResetPasswordAction>({
    types: [
      Auth.VERIFY_PW_RESET_KEY_REQUEST,
      Auth.VERIFY_PW_RESET_KEY_SUCCESS,
      Auth.VERIFY_PW_RESET_KEY_FAILURE,
    ],
    endpoint: API.auth.resetPassword(key, uidb36),
    method: 'GET',
    meta: {
      toast: {
        success: false,
      },
    },
  });
};

const fetchSocialProviders = () => {
  return callAPI<FetchSocialProvidersAction>({
    types: [
      Auth.FETCH_SOCIAL_PROVIDERS_REQUEST,
      Auth.FETCH_SOCIAL_PROVIDERS_SUCCESS,
      Auth.FETCH_SOCIAL_PROVIDERS_FAILURE,
    ],
    endpoint: API.auth.socialProviders,
    method: 'GET',
  });
};

const fetchSocialConnections = () => {
  return callAPI<FetchSocialConnectionsAction>({
    types: [
      Auth.FETCH_SOCIAL_CONNECTIONS_REQUEST,
      Auth.FETCH_SOCIAL_CONNECTIONS_SUCCESS,
      Auth.FETCH_SOCIAL_CONNECTIONS_FAILURE,
    ],
    endpoint: API.auth.socialConnections,
    method: 'GET',
  });
};

const removeSocialConnection = (id: string, provider: string) => {
  return callAPI<RemoveSocialConnectionAction>({
    types: [
      Auth.REMOVE_SOCIAL_CONNECTION_REQUEST,
      Auth.REMOVE_SOCIAL_CONNECTION_SUCCESS,
      Auth.REMOVE_SOCIAL_CONNECTION_FAILURE,
    ],
    endpoint: API.auth.removeSocialConnection,
    method: 'DELETE',
    body: {
      id,
      provider,
    },
    meta: {
      toast: {
        success: 'Social Account successfully disconnected',
        error: true,
      },
    },
  });
};

export const confirmEmail = (key: string) => {
  return callAPI<ConfirmEmailAction>({
    types: [
      Auth.CONFIRM_EMAIL_REQUEST,
      Auth.CONFIRM_EMAIL_SUCCESS,
      Auth.CONFIRM_EMAIL_FAILURE,
    ],
    endpoint: API.auth.confirmEmail(key),
    method: 'GET',
    meta: {
      toast: {
        success: false,
      },
    },
  });
};

export const fetchRoadmapToken = ({ avatar }: { avatar?: string }) => {
  return callAPI<FetchRoadmapTokenAction>({
    types: [
      Auth.FETCH_ROADMAP_TOKEN_REQUEST,
      Auth.FETCH_ROADMAP_TOKEN_SUCCESS,
      Auth.FETCH_ROADMAP_TOKEN_FAILURE,
    ],
    endpoint: API.auth.roadmap,
    method: 'POST',
    body: {
      avatar,
    },
    meta: { toast: false },
  });
};

const social = {
  removeConnection: removeSocialConnection,
  fetchConnections: fetchSocialConnections,
  fetchProviders: fetchSocialProviders,
};

const password = {
  change: changePassword,
  reset: resetPassword,
  verify: verifyPasswordResetKey,
};

export { social, password };
