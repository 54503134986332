import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { FaApple } from 'react-icons/fa';

import { CLIP_NAME } from 'constants/common';

import { videoClipActions } from 'redux/actions/cms';

import styled, {
  Box,
  Flex,
  Stack,
  Grid,
  GridItem,
  MdIcon,
  Text,
  Spinner,
  Button,
  Image,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useTheme,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  LightMode,
  Divider,
  chakra,
  useMediaQuery,
} from '@workshop/ui';

import { PLATFORM } from 'constants/env';
import { STATUS } from 'constants/background';
import { useUploadList } from 'redux/selectors/background';
import { hooks } from 'utils';

import { StandaloneImageUpload } from 'components/Common';
import { UploadStatus } from 'components/AppHeader/UploadStatusButton';
import { AudioVisualizer } from 'components/AudioVisualizer';
import { MediaRecorder } from 'components/MediaRecorder';

import { ClipProgress, VideoClipsPlayerProps, VideoPlayerProps } from './types';

export const orientationStyleMapping = {
  portrait: {
    width: '100%',
    paddingTop: '177.7%',
  },
  landscape: {
    width: '100%',
    height: '0',
    paddingTop: '28.2%',
    paddingBottom: '28.2%',
  },
  square: {
    width: '100%',
    paddingTop: '100%',
  },
};

export const containerStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
} as const;

export const StyledVideo = styled.div`
  width: 100%;
  height: 100%;
  video {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const MEDIA_ELEMENT_STREAMS = new WeakMap();
const audioCtx = new window.AudioContext();

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  id,
  autoplay,
  autoPlayNext = false,
  clip,
  nextIdx,
  currentIdx,
  orientation,
  stepsTotal,
  isEditable = false,
  onUpload,
  qrBlob,
  mediaType,
  onSaveClip,
  hasMedia,
  embed,
  onBlurBackground,
}) => {
  const fileInput = useRef<HTMLInputElement>();
  const {
    onClickNext,
    onClickPrev,
    showNextBtn,
    showPrevBtn,
    src,
    srcHq,
    summary,
  } = clip;
  const orientationStyles = orientationStyleMapping[orientation];

  const playerRef = useRef<ReactPlayer>(null);
  const autoPlayTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const dispatch = useDispatch();

  const [clipProgress, setClipProgress] = useState<ClipProgress>({
    // Amount of video loaded & played (%)
    loaded: 0,
    played: 0,
    // Amount of video loaded & played (seconds)
    loadedSeconds: 0,
    playedSeconds: 0,
  });

  const [clipDuration, setClipDuration] = useState(0);
  const [clipLoaded, setClipLoaded] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);

  // Init state for handling the animating/transitioning of video clips
  // on clip change.
  const [screenshot, setScreenshot] = useState('');
  const [screenshotOpacity, setScreenshotOpacity] = useState('0%');
  const [clipTranslations, setClipTranslations] = useState({
    screenshotTranslateX: '0%',
    videoTranslateX: '0%',
  });
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isIndicatorTransitioning, setIsIndicatorTransitioning] =
    useState(false);
  const [recordingClip, setRecordingClip] = useState<'video' | 'audio' | null>(
    null
  );
  const [isEditing, setIsEditing] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [playbackError, setPlaybackError] = useState(false);
  const [appStore, setAppStore] = useState<'ios' | 'android' | null>(null);
  const [showQrCode, setShowQrCode] = useState(false);
  const [subtitlesText, setSubtitlesText] = useState(null);

  const [stream, setStream] = useState<MediaStream | null>(null);

  const [indicatorWidths, setIndicatorWidths] = useState<{
    current: number | '150px';
    next: number | '150px';
  }>({
    current: '150px',
    next: 2,
  });

  const [isTouchDevice] = useMediaQuery('(hover: none)');
  const [isMobile] = useMediaQuery('(max-width: 500px)');

  const theme = useTheme();

  const animateIndicators = async () => {
    setIsIndicatorTransitioning(true);
    await timeout(200);
    setIndicatorWidths({ current: 2, next: '150px' });
    await timeout(500);
    setIndicatorWidths({ current: '150px', next: 2 });
    setIsIndicatorTransitioning(false);
  };

  const changeClip = async (direction: 'next' | 'previous') => {
    /**
     * To create a swiping animation from clip to clip, we take a screenshot
     * of the current state of the video and position the resulting image over the
     * video, we then run a string of CSS changes to move the video (which will contain
     * a new clip source) to the side of the screenshot, then animate the image and
     * video together to create the illusion of swiping from one clip to another
     * (animations are set via css "transitions" on the relevant elements)
     */

    animateIndicators();

    // Canvas screenshot doesn't currently work on Mac Safari, so skip animation on Safari
    // @ts-ignore
    const isSafari = window.safari !== undefined;
    if (isSafari) return;
    setIsTransitioning(true);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const video = playerRef.current?.getInternalPlayer();

    if (context && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // "Double request animation frame" to ensure smooth animation
      requestAnimationFrame(() => {
        requestAnimationFrame(async () => {
          // Wrapped in a 'try' as it occasionally fails
          try {
            context.drawImage(
              video as HTMLVideoElement,
              0,
              0,
              video.videoWidth,
              video.videoHeight
            );
            const dataURI = canvas.toDataURL();
            // Init transition by showing the screenshot overlay
            setScreenshot(dataURI);
            setScreenshotOpacity('100%');
            await timeout(100);
            // Start transition
            setClipTranslations({
              screenshotTranslateX: '0%',
              videoTranslateX: direction === 'next' ? '100%' : '-100%',
            });
            await timeout(500);
            requestAnimationFrame(() =>
              setClipTranslations({
                screenshotTranslateX: direction === 'next' ? '-100%' : '100%',
                videoTranslateX: '0%',
              })
            );
            await timeout(500);
            // Restart the video playing and reset transition state
            setIsPlaying(true);
            setScreenshotOpacity('0%');
            setScreenshot('');
            await timeout(100);
            setClipTranslations({
              screenshotTranslateX: '0%',
              videoTranslateX: '0%',
            });
          } catch {}
        });
      });
    }
    setIsTransitioning(false);
  };

  // If the `nextIdx` prop changes, animate to the next clip
  useEffect(() => {
    if (nextIdx !== currentIdx) {
      changeClip(nextIdx > currentIdx ? 'next' : 'previous');
      setStream(null);
    }
  }, [nextIdx, currentIdx]);

  useEffect(() => {
    /**
     * Start playing the video if autoplay is set to true
     * after changing the video source
     */
    if (!src || !autoplay || isPlaying) return;
    setIsPlaying(true);
    setRefreshing(true);
    setProcessing(false);
    setTimeout(() => {
      setRefreshing(false);
    }, 1);
  }, [src]);

  hooks.useDeepEqualEffect(() => {
    /**
     * Runs after handleOnProgress
     * (every 100ms - see progressInterval prop on ReactPlayer)
     */

    /**
     * Move to the next clip and start playing it automatically:
     * - if autoPlayNext is enabled
     * - after the current clip has finished playing (i.e, clipProgress.played === 1)
     * - if there is another clip
     */
    if (!autoPlayNext || clipProgress.played !== 1) return;
    if (!stepsTotal || currentIdx + 1 >= stepsTotal) return;

    onClickNext && onClickNext();
    setIsPlaying(true);
  }, [clipProgress]);

  const handleOnReady = () => {
    if (refreshCount > 0) {
      setRefreshCount(0);
    }
    if (autoplay) {
      // Set isPlaying to false if this timeout isn't cleared in handleOnProgress
      // (i.e. likely that autoplay was blocked by the browser)
      autoPlayTimeout.current = setTimeout(() => setIsPlaying(false), 2000);
    }
  };

  const setAudioStream = () => {
    if (mediaType === 'audio' && !stream) {
      const audio = playerRef.current?.getInternalPlayer();
      if (audio && !audio?.captureStream) {
        // Safari doesn't support captureStream, so we use another method
        // to connect audio stream to visualiser
        if (MEDIA_ELEMENT_STREAMS.has(audio)) {
          setStream(MEDIA_ELEMENT_STREAMS.get(audio));
          return;
        }
        // @ts-ignore
        const sourceNode = audioCtx.createMediaElementSource(audio);
        // Create a stream from our AudioContext
        const dest = audioCtx.createMediaStreamDestination();
        MEDIA_ELEMENT_STREAMS.set(audio, dest.stream);
        // Connect our media element's output to the stream
        sourceNode.connect(dest);
        setStream(dest.stream);
        const analyser = audioCtx.createAnalyser();
        // Audio should output to the analyser.
        sourceNode.connect(analyser);
        // Analyser should output to the audio device.
        analyser.connect(audioCtx.destination);
      } else {
        const audioStream = audio?.captureStream && audio.captureStream();
        if (audioStream && audioStream.getAudioTracks().length > 0) {
          setStream(audioStream);
        }
      }
    }
  };

  const handleOnDuration = (duration: number) => {
    setClipLoaded(true);
    setClipDuration(duration);
  };

  const handleOnPlay = () => {
    if (stream) {
      setStream(null);
    }
  };

  // Setup video player callbacks
  const handleOnProgress = (args: ClipProgress) => {
    setClipProgress(args);
    setAudioStream();
    if (args.played > 0 && autoPlayTimeout.current) {
      clearTimeout(autoPlayTimeout.current);
    }
    // TODO:SUBTITLES
    // const track = document.getElementsByTagName('track')[0]?.track;
    // if (track) {
    //   track.mode = 'hidden';
    // }
    // const cue = track?.activeCues[0];
    // if (cue) {
    //   const cueText = cue.text;
    //   setSubtitlesText(cueText);
    // } else if (subtitlesText) {
    //   setSubtitlesText(null);
    // }
  };

  const handleOnError = (e: any, data: any) => {
    if (data?.response?.code === 403 && data?.details === 'manifestLoadError') {
      if (refreshCount < 10) {
        setRefreshing(true);
        setRefreshCount(refreshCount + 1);
        // Poll for HLS asset after uploading
        setTimeout(() => setRefreshing(false), 10000);
      } else {
        // Try refreshing content 10 times, then fall back to error state
        setPlaybackError(true);
      }
    }
  };

  // If there is an in progress or completed video upload related to this
  // video clip, then extract it from the state so that we're able to display
  // upload progress metadata.
  const uploadList = useUploadList();
  const clipUpload = Object.values(uploadList).find(
    ({ metadata }) =>
      metadata && 'id' in metadata && metadata.id === id.toString()
  );

  // If an upload exists for this clip and the status isn't `completed`
  // then we have an in progress upload
  const uploadInProgress = clipUpload && clipUpload.status !== STATUS.completed;

  useEffect(() => {
    if (uploadInProgress) {
      setProcessing(true);
    }
  }, [uploadInProgress]);

  const showControls =
    (clipLoaded || mediaType === 'image') &&
    (!isEditable || src) &&
    !isEditing &&
    !playbackError &&
    !refreshing &&
    !uploadInProgress &&
    !isTransitioning;

  const progressPercent =
    mediaType === 'image' || !showControls ? 100 : clipProgress.played * 100;

  const blurBackground =
    mediaType === 'text'
      ? true
      : mediaType === 'image' && src
      ? !isPlaying
      : !playbackError &&
        !refreshing &&
        !uploadInProgress &&
        (!isPlaying || progressPercent === 100) &&
        !isTransitioning;

  useEffect(() => {
    if (onBlurBackground) {
      onBlurBackground(blurBackground);
    }
  }, [blurBackground, onBlurBackground]);

  const mediaTypes: {
    name: string;
    icon: string;
    slug: 'video' | 'audio' | 'image' | 'text';
  }[] = [
    {
      name: 'Video',
      icon: 'Movie',
      slug: 'video',
    },
    {
      name: 'Audio',
      icon: 'Mic',
      slug: 'audio',
    },
    {
      name: 'Picture',
      icon: 'PhotoCamera',
      slug: 'image',
    },
    {
      name: 'Just Text',
      icon: 'Title',
      slug: 'text',
    },
  ];

  const indicators = (
    <Flex width="100%" alignItems="center" zIndex={3} pointerEvents="auto">
      <Flex width="20%" justifyContent="center" h={7}>
        {Boolean(showPrevBtn && onClickPrev) && (
          <>
            <LightMode>
              <Button
                icon="ArrowBack"
                colorScheme="blackAlpha"
                opacity={0.8}
                borderRadius="full"
                height={7}
                width={7}
                minWidth={7}
                px={0}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickPrev && onClickPrev();
                }}
              />
            </LightMode>
          </>
        )}
      </Flex>
      {/** CLIP(s) PROGRESS INDICATORS */}
      <Flex width="60%" justifyContent="center" alignItems="center">
        {Array.from(Array(stepsTotal)).map((c, idx) =>
          idx === currentIdx && !isIndicatorTransitioning ? (
            <Slider
              key={`slider-${idx}`}
              aria-label="Video-progress"
              marginX={1}
              flex={1}
              maxW="150px"
              focusThumbOnChange={false}
              value={progressPercent}
              onChange={(value) => {
                playerRef.current?.seekTo(value / 100, 'fraction');
              }}
            >
              <SliderTrack height={2} backgroundColor="neutralLightAlpha.400">
                <SliderFilledTrack
                  backgroundColor="common.progress"
                  transition="width 100ms linear"
                />
              </SliderTrack>
              <SliderThumb
                opacity={0}
                transition="opacity 200ms ease-in, left 100ms linear"
                _groupHover={{
                  opacity: progressPercent === 100 ? 0 : 1,
                }}
              />
            </Slider>
          ) : (
            <Box
              key={`indicator-${idx}`}
              backgroundColor={
                idx < currentIdx ||
                (isIndicatorTransitioning &&
                  idx === currentIdx &&
                  nextIdx > currentIdx)
                  ? 'common.progress'
                  : 'neutralLightAlpha.500'
              }
              borderRadius="4px"
              minW={2}
              height={2}
              marginX={1}
              transition="width 0.4s"
              {...(isIndicatorTransitioning
                ? {
                    width:
                      idx === currentIdx
                        ? indicatorWidths.current
                        : idx === nextIdx
                        ? indicatorWidths.next
                        : 2,
                  }
                : {
                    width: 2,
                  })}
            />
          )
        )}
      </Flex>
      <Flex width="20%" justifyContent="center" h={7}>
        {Boolean(showNextBtn && onClickNext) && (
          <LightMode>
            <Button
              icon="ArrowForward"
              colorScheme="blackAlpha"
              opacity={0.8}
              borderRadius="full"
              height={7}
              width={7}
              minWidth={7}
              px={0}
              onClick={(e) => {
                e.stopPropagation();
                onClickNext && onClickNext();
              }}
            />
          </LightMode>
        )}
      </Flex>
    </Flex>
  );

  return (
    <Box
      {...orientationStyles}
      position="relative"
      transition={{ base: 'all 0.5s', lg: '' }}
    >
      <Flex
        {...containerStyle}
        alignItems="center"
        backgroundColor={mediaType === 'audio' ? 'background.primary' : 'black'}
        justifyContent="center"
        zIndex={0}
        overflow="hidden"
        // Needed for Safari to respect hidden overflow over video
        transform="translate3d(0, 0, 0)"
      >
        <StyledVideo>
          {screenshot ? (
            <Image
              src={screenshot}
              opacity={screenshotOpacity}
              position="absolute"
              width="100%"
              height="100%"
              objectFit="cover"
              transform={`translateX(${clipTranslations.screenshotTranslateX})`}
              transition="opacity 0.1s, transform 0.5s ease-in-out"
              backgroundColor="background.tint4"
              zIndex={1}
            />
          ) : null}
          {(!isEditable || src) &&
            !clipLoaded &&
            mediaType !== 'image' &&
            !playbackError && (
              <Flex
                alignItems="center"
                backgroundColor="neutralAlpha.200"
                height="100%"
                justifyContent="center"
                width="100%"
                zIndex={1}
              >
                <Spinner color="neutral.700" />
              </Flex>
            )}
          {uploadInProgress && clipUpload ? (
            <Flex
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              flexDir="column"
              zIndex={1}
            >
              <Flex maxW="400px" px={12} mb={8}>
                <UploadStatus
                  id={id.toString()}
                  upload={clipUpload}
                  displayText={false}
                  displayBytes
                  light
                  centered
                />
              </Flex>
            </Flex>
          ) : processing ? (
            <Flex
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              backgroundColor="neutral.300"
              flexDirection="column"
              textAlign="center"
              p={6}
            >
              <Spinner color="neutral.700" size="sm" />
              {mediaType === 'video' && (
                <>
                  <Text color="neutral.700" mt={4} fontWeight="semibold">
                    Video processing
                  </Text>
                  <Text color="neutral.700" mt={2} fontSize="sm">
                    You can keep working on your session, this might take a few
                    minutes.
                  </Text>
                </>
              )}
            </Flex>
          ) : null}
          <Flex
            width="calc(100% + 2px)"
            height="100%"
            transform={`translateX(${clipTranslations.videoTranslateX})`}
            transition="transform 0.5s ease-in-out"
          >
            {Boolean(isEditable && ((hasMedia && !src) || isEditing)) &&
              !!onSaveClip && (
                <Flex
                  width="100%"
                  height="100%"
                  backgroundColor={
                    isEditing
                      ? 'background.defaultTransparent'
                      : 'background.tint1'
                  }
                  alignItems="center"
                  justifyContent="center"
                  flexDir="column"
                  position="absolute"
                  pb={12}
                  zIndex={1}
                >
                  <Flex
                    alignItems="center"
                    flexDir="column"
                    m={4}
                    position="relative"
                  >
                    {!mediaType && (
                      <Text
                        textAlign="center"
                        fontSize="sm"
                        fontWeight="semibold"
                        maxWidth={150}
                        color="text.muted"
                        pb={4}
                      >
                        {`Select a media type for this ${CLIP_NAME}:`}
                      </Text>
                    )}
                    {!showQrCode && (
                      <Grid
                        templateColumns={
                          mediaType ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)'
                        }
                        gap={4}
                      >
                        {mediaTypes.map((mt) => (
                          <GridItem key={`mediaType-${mt.slug}`}>
                            <Flex
                              flex={1}
                              borderWidth={2}
                              borderColor={
                                mt.slug === mediaType
                                  ? 'common.primary'
                                  : 'border.muted'
                              }
                              backgroundColor={
                                mt.slug === mediaType
                                  ? 'background.default'
                                  : 'background.tint1'
                              }
                              borderRadius={mediaType ? 'full' : 'md'}
                              p={mediaType ? 3 : 4}
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              cursor="pointer"
                              _hover={{ backgroundColor: 'background.default' }}
                              onClick={() => onSaveClip({ clipType: mt.slug })}
                              // {...(mt.disabled
                              //   ? {
                              //       pointerEvents: 'none',
                              //       opacity: 0.5,
                              //     }
                              //   : {})}
                            >
                              <Box>
                                <MdIcon
                                  name={mt.icon}
                                  boxSize={6}
                                  color="common.primary"
                                />
                              </Box>
                              {!mediaType && (
                                <Box mt={1}>
                                  <Text
                                    fontSize="md"
                                    fontWeight="bold"
                                    color="common.primary"
                                  >
                                    {mt.name}
                                  </Text>
                                </Box>
                              )}
                            </Flex>
                          </GridItem>
                        ))}
                      </Grid>
                    )}

                    {mediaType === 'video' ? (
                      <Stack
                        direction={
                          orientation === 'landscape' ? 'row' : 'column'
                        }
                        gap={2}
                        mt={6}
                      >
                        {showQrCode ? (
                          <>
                            {appStore === 'ios' ? (
                              <Flex alignItems="center" flexDir="column" m={4}>
                                <Button
                                  size="xs"
                                  variant="ghost"
                                  onClick={() => setAppStore(null)}
                                  icon="ArrowBack"
                                  mb={{ base: 1, md: 2 }}
                                >
                                  Back
                                </Button>
                                <Text
                                  textAlign="center"
                                  fontSize={isMobile ? 'xs' : 'sm'}
                                  fontWeight="semibold"
                                  maxWidth={200}
                                  color="text.muted"
                                  pb={isMobile ? 1 : 3}
                                  pt={1}
                                >
                                  Download the iOS app{' '}
                                  <chakra.a
                                    href="https://apps.apple.com/gb/app/steppit/id1660615987"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    color="text.primary"
                                  >
                                    here
                                  </chakra.a>
                                  :
                                </Text>
                                <Box
                                  backgroundColor="white"
                                  borderRadius="md"
                                  p={2}
                                  boxShadow="lg"
                                >
                                  {/* @ts-ignore */}
                                  <QRCode
                                    value="https://apps.apple.com/gb/app/steppit/id1660615987"
                                    size={
                                      isMobile && orientation !== 'portrait'
                                        ? 80
                                        : 128
                                    }
                                  />
                                </Box>
                              </Flex>
                            ) : appStore === 'android' ? (
                              <Flex
                                alignItems="center"
                                flexDir="column"
                                m={4}
                              ></Flex>
                            ) : (
                              <Flex
                                alignItems="center"
                                flexDir={
                                  orientation === 'portrait' ? 'column' : 'row'
                                }
                                m={4}
                              >
                                <Flex alignItems="center" flexDir="column">
                                  <Button
                                    size="xs"
                                    icon="ArrowBack"
                                    onClick={() => setShowQrCode(false)}
                                    variant="ghost"
                                    mb={{ base: 1, md: 2 }}
                                  >
                                    Back
                                  </Button>
                                  <Text
                                    textAlign="center"
                                    fontSize={isMobile ? 'xs' : 'sm'}
                                    fontWeight="semibold"
                                    maxWidth={150}
                                    color="text.muted"
                                    pb={isMobile ? 1 : 3}
                                  >
                                    {isMobile
                                      ? 'Scan in the app:'
                                      : 'Scan in the app to film from your phone:'}
                                  </Text>
                                  <Box
                                    backgroundColor="white"
                                    borderRadius="md"
                                    p={2}
                                    boxShadow="lg"
                                    mb={4}
                                  >
                                    {/* @ts-ignore */}
                                    <QRCode
                                      // @ts-ignore
                                      value={qrBlob}
                                      size={
                                        isMobile && orientation !== 'portrait'
                                          ? 80
                                          : 128
                                      }
                                    />
                                  </Box>
                                </Flex>
                                <Flex
                                  display={
                                    orientation === 'portrait' ? 'none' : 'flex'
                                  }
                                  height="100%"
                                  borderRightWidth={1}
                                  mx={{ base: 4, md: 8 }}
                                />
                                <Flex alignItems="center" flexDir="column">
                                  <Button
                                    icon="Refresh"
                                    variant="ghost"
                                    onClick={() =>
                                      dispatch(
                                        videoClipActions.retrieve(id as number)
                                      )
                                    }
                                    size={isMobile ? 'xs' : 'sm'}
                                  >
                                    Refresh Media
                                  </Button>
                                  <Divider my={isMobile ? 2 : 3} />
                                  <Text
                                    textAlign="center"
                                    fontSize="xs"
                                    fontWeight="semibold"
                                    maxWidth={150}
                                    color="text.muted"
                                    pb={3}
                                  >
                                    Haven't got the app?
                                  </Text>
                                  <Button
                                    leftIcon={<FaApple />}
                                    size={isMobile ? 'xs' : 'sm'}
                                    mb={3}
                                    onClick={() => setAppStore('ios')}
                                  >
                                    {isMobile
                                      ? 'Download on iOS'
                                      : 'Download Steppit on iOS'}
                                  </Button>
                                  <Text
                                    textAlign="center"
                                    fontSize="xs"
                                    fontWeight="semibold"
                                    maxWidth={150}
                                    color="text.muted"
                                    whiteSpace="nowrap"
                                  >
                                    (Coming soon to Android)
                                  </Text>
                                </Flex>
                              </Flex>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={() => setRecordingClip('video')}
                              icon="FiberManualRecord"
                              colorScheme="red"
                              fontSize="sm"
                              isLoading={uploadInProgress}
                              isDisabled={uploadInProgress}
                              display={isTouchDevice ? 'none' : 'block'}
                            >
                              Record Video
                            </Button>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsPlaying(false);
                                fileInput.current?.click();
                              }}
                              icon="FileUpload"
                              fontSize="sm"
                              isLoading={uploadInProgress}
                              isDisabled={uploadInProgress}
                            >
                              Upload Video
                            </Button>
                            {Boolean(qrBlob) && PLATFORM === 'steppit' && (
                              <Button
                                onClick={() => {
                                  setShowQrCode(true);
                                }}
                                variant="outline"
                                icon="AddToHomeScreen"
                                fontSize="sm"
                                isLoading={uploadInProgress}
                                isDisabled={uploadInProgress}
                              >
                                Film By Phone
                              </Button>
                            )}
                          </>
                        )}

                        {/* {Boolean(qrBlob) && PLATFORM === 'steppit' && (
                          <Flex display={{ base: 'none', md: 'flex' }}>
                            <Popover placement="top" closeOnBlur>
                              <PopoverTrigger>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  colorScheme="gray"
                                  icon="AddToHomeScreen"
                                  fontSize="sm"
                                  isLoading={uploadInProgress}
                                  isDisabled={uploadInProgress}
                                >
                                  Film By Phone
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent
                                width="image.3xl"
                                background={
                                  appStore === null
                                    ? 'background.tint2'
                                    : 'background.primary'
                                }
                              >
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody>
                                  
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        )} */}
                      </Stack>
                    ) : mediaType === 'audio' ? (
                      <Stack
                        direction={
                          orientation === 'landscape' ? 'row' : 'column'
                        }
                        gap={2}
                        mt={6}
                      >
                        <Button
                          onClick={() => setRecordingClip('audio')}
                          icon="FiberManualRecord"
                          colorScheme="red"
                          fontSize="sm"
                          isLoading={uploadInProgress}
                          isDisabled={uploadInProgress}
                        >
                          Record Audio
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsPlaying(false);
                            fileInput.current?.click();
                          }}
                          icon="FileUpload"
                          fontSize="sm"
                          isLoading={uploadInProgress}
                          isDisabled={uploadInProgress}
                        >
                          Upload Audio
                        </Button>
                      </Stack>
                    ) : mediaType === 'image' ? (
                      <Stack gap={2} mt={6}>
                        <StandaloneImageUpload
                          id="image"
                          name="image"
                          label=""
                          textColor="text.muted"
                          // textBgColor={
                          //   imagePreview.landscape || landscape
                          //     ? 'background.defaultTransparent'
                          //     : 'transparent'
                          // }
                          textBgColor="transparent"
                          backgroundColor="background.tint3"
                          // height={{
                          //   base: 'image.xl',
                          //   sm: 'image.2xl',
                          //   md: 'image.3xl',
                          //   '2xl': 'image.2xl',
                          // }}
                          // width="100%"
                          width="image.3xl"
                          height="image.3xl"
                          // image={imagePreview.landscape || landscape}
                          image=""
                          onDrop={async (
                            fieldName: string,
                            acceptedFiles: File[]
                          ) => {
                            onSaveClip &&
                              (await onSaveClip({ image: acceptedFiles[0] }));
                            setIsEditing(false);
                          }}
                          backgroundSize="contain"
                          showBlur
                        />
                      </Stack>
                    ) : null}
                    {isEditing && (
                      <LightMode>
                        <Button
                          mt={6}
                          icon="Cancel"
                          colorScheme="blackAlpha"
                          fontSize="sm"
                          onClick={() => setIsEditing(false)}
                        >
                          Stop Editing
                        </Button>
                      </LightMode>
                    )}
                  </Flex>
                  <Box position="absolute" top={4} right={4}>
                    <LightMode>
                      <Button
                        icon="ScreenRotation"
                        borderRadius="full"
                        w={7}
                        h={7}
                        minW={7}
                        px={0}
                        opacity={0.8}
                        colorScheme="blackAlpha"
                        transform={[
                          orientation === 'portrait'
                            ? 'rotate(45deg)'
                            : 'rotate(135deg)',
                        ]}
                        onClick={() => {
                          onSaveClip &&
                            onSaveClip({
                              orientation:
                                orientation === 'portrait'
                                  ? 'landscape'
                                  : 'portrait',
                            });
                        }}
                      />
                    </LightMode>
                  </Box>
                </Flex>
              )}
            {mediaType === 'image' && src ? (
              <Box
                {...orientationStyles}
                position="relative"
                filter={!isPlaying ? 'blur(10px)' : 'none'}
                transform={!isPlaying ? ['scale(1.1)'] : []}
                transition="all 0.3s"
              >
                <Box
                  {...containerStyle}
                  backgroundImage={`url(${src})`}
                  backgroundPosition="center"
                  backgroundSize="cover"
                  filter="blur(50px)"
                  transform={['scale(1.1)']}
                />
                <Box
                  {...containerStyle}
                  backgroundImage={`url(${src})`}
                  backgroundPosition="center"
                  backgroundSize="contain"
                  backgroundRepeat="no-repeat"
                />
              </Box>
            ) : refreshing ? (
              <Flex
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                backgroundColor="neutral.300"
                flexDirection="column"
                textAlign="center"
                p={6}
              >
                <Spinner color="neutral.700" size="sm" />
                {mediaType === 'video' && (
                  <>
                    <Text color="neutral.700" mt={4} fontWeight="semibold">
                      Video processing
                    </Text>
                    <Text color="neutral.700" mt={2} fontSize="sm">
                      You can keep working on your session, this might take a
                      few minutes.
                    </Text>
                  </>
                )}
              </Flex>
            ) : playbackError ? (
              <Flex
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                textAlign="center"
              >
                <MdIcon name="Error" boxSize={6} color="neutral.200" mb={2} />
                <Text
                  color="neutral.200"
                  mb={6}
                  whiteSpace="break-spaces"
                  fontSize="sm"
                >
                  {'Oops, something went wrong!\nPlease refresh the page.'}
                </Text>
                <Button
                  icon="Refresh"
                  colorScheme="whiteAlpha"
                  onClick={() => window.location.reload()}
                  size="sm"
                >
                  Refresh Page
                </Button>
              </Flex>
            ) : (
              src && (
                <Flex
                  width="100%"
                  height="100%"
                  filter={blurBackground ? 'blur(10px)' : 'none'}
                  transform={blurBackground ? ['scale(1.1)'] : []}
                  transition="all 0.3s"
                >
                  {mediaType === 'audio' && stream && (
                    <Flex
                      position="absolute"
                      width="100%"
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <AudioVisualizer
                        stream={stream}
                        options={{
                          barColor: theme.colors.common.primary,
                          shadowColor: theme.colors.common.primaryTransparent,
                        }}
                      />
                    </Flex>
                  )}
                  {/* @ts-ignore */}
                  <ReactPlayer
                    ref={playerRef}
                    width="100%"
                    height="100%"
                    onDuration={handleOnDuration}
                    onProgress={handleOnProgress}
                    playing={isPlaying}
                    onReady={handleOnReady}
                    onPlay={handleOnPlay}
                    onEnded={() => setStream(null)}
                    progressInterval={100}
                    url={
                      PLATFORM === 'steppit'
                        ? src.replace('cdn.workshop.ws', 'cdn.steppit.com')
                        : src
                    }
                    playsinline
                    onError={handleOnError}
                    config={{
                      file: {
                        attributes: {
                          crossOrigin: 'anonymous',
                        },
                        // TODO:SUBTITLES
                        // tracks: [
                        //   {
                        //     kind: 'captions',
                        //     src: '',
                        //     srcLang: 'en',
                        //     default: true,
                        //   },
                        // ],
                        hlsOptions: {
                          // @ts-ignore
                          xhrSetup: (xhr, url) => {
                            // xhr.withCredentials = true;
                            xhr.open(
                              'GET',
                              `${url}?t=${new Date().getTime()}`,
                              true
                            );
                          },
                        },
                      },
                    }}
                  />
                </Flex>
              )
            )}
          </Flex>
        </StyledVideo>
      </Flex>
      {showControls ? (
        <Flex
          {...containerStyle}
          alignItems="flex-end"
          justifyContent="center"
          pb={{ base: 3, md: 7 }}
          role="group"
          {...(embed
            ? {
                height: 'auto',
                width: { base: 'auto', lg: '100%' },
                sx: {
                  bottom: 'calc(50% - 50vh)',
                  top: 'calc(50% - 50vh)',
                  left: { base: 'calc(50% - 50vw)', lg: 0 },
                  right: { base: 'calc(50% - 50vw)', lg: 0 },
                  '@supports (height: 100dvh)': {
                    bottom: 'calc(50% - 50dvh)',
                    top: 'calc(50% - 50dvh)',
                  },
                },
              }
            : {})}
        >
          {/** CLIP CONTROLS (play/pause/skip) */}
          <Box
            {...containerStyle}
            height="100%"
            zIndex={2}
            backgroundColor="neutralAlpha.500"
            onClick={() => setIsPlaying(!isPlaying)}
            pointerEvents={uploadInProgress ? 'none' : 'auto'}
            opacity={
              !isPlaying || (progressPercent === 100 && mediaType !== 'image')
                ? 1
                : 0
            }
            transition="all 200ms ease-in"
            backdropFilter={blurBackground ? 'blur(10px)' : 'none'}
          >
            <Flex
              {...containerStyle}
              flexDirection="column"
              my="auto"
              overflow="scroll"
              sx={{
                maxHeight: 'calc(100vh - 90px)',
                '@supports (height: 100dvh)': {
                  maxHeight: 'calc(100dvh - 90px)',
                },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none',
                '::-webkit-scrollbar': { display: 'none' },
              }}
              pointerEvents={!blurBackground ? 'none' : 'auto'}
            >
              <Flex flexDirection="column" my="auto" w="100%">
                {Boolean(!isTransitioning) && (
                  <>
                    {Boolean(summary) && (
                      <Flex
                        px={4}
                        pb={{ base: 3, md: 6 }}
                        mx="auto"
                        display={{
                          base: 'flex',
                          lg: orientation === 'landscape' ? 'flex' : 'none',
                        }}
                        maxW="800px"
                      >
                        <Text
                          color="text.light"
                          fontWeight="semibold"
                          textAlign="center"
                          fontSize={{ base: 'sm', md: 'md' }}
                        >
                          {summary}
                        </Text>
                      </Flex>
                    )}
                    <Flex
                      mx="auto"
                      display={{
                        base: 'flex',
                        lg: orientation === 'landscape' ? 'flex' : 'none',
                      }}
                      mb={{ base: 5, md: 8 }}
                    >
                      {Boolean(showPrevBtn && onClickPrev) && (
                        <Button
                          secondary
                          mx={1}
                          mt={2}
                          fontSize={{ base: 'sm', md: 'md' }}
                          size="sm"
                          onClick={onClickPrev}
                          icon="ArrowBack"
                          w={8}
                        />
                      )}
                      {Boolean(showNextBtn && onClickNext) && (
                        <Button
                          mx={1}
                          mt={2}
                          minWidth="100px"
                          fontSize={{ base: 'sm', md: 'md' }}
                          size="sm"
                          onClick={onClickNext}
                          icon="ArrowForward"
                          iconPosition="right"
                        >
                          Next
                        </Button>
                      )}
                    </Flex>
                  </>
                )}
                {Boolean(progressPercent === 100 && mediaType !== 'image') && (
                  <Flex alignItems="center" paddingX={4}>
                    <Flex flex={1} justify="center">
                      <Box
                        backgroundColor="neutralLightAlpha.300"
                        _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                        borderRadius="50%"
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          playerRef.current?.seekTo(0);
                          setIsPlaying(true);
                        }}
                        padding={1}
                      >
                        <MdIcon color="white" name="Replay" boxSize={6} />
                      </Box>
                    </Flex>
                  </Flex>
                )}
                {Boolean(progressPercent !== 100 && mediaType !== 'image') && (
                  <Flex alignItems="center">
                    <Flex w="20%" justifyContent="center">
                      <Box
                        backgroundColor="neutralLightAlpha.300"
                        _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                        borderRadius="50%"
                        cursor="pointer"
                        padding={1}
                        onClick={(e) => {
                          e.stopPropagation();
                          // Move 10 second backward
                          let prevTenSeconds =
                            (playerRef.current?.getCurrentTime() || 0) - 10;

                          playerRef.current?.seekTo(prevTenSeconds);
                        }}
                      >
                        <MdIcon name="Replay10" color="white" boxSize={6} />
                      </Box>
                    </Flex>
                    <Flex flex={1} justify="center">
                      <Box
                        backgroundColor="neutralLightAlpha.300"
                        _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                        borderRadius="50%"
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsPlaying(!isPlaying);
                        }}
                        padding={1}
                      >
                        <MdIcon
                          color="white"
                          name={isPlaying ? 'Pause' : 'PlayArrow'}
                          boxSize={embed ? 6 : 8}
                        />
                      </Box>
                    </Flex>
                    <Flex w="20%" justifyContent="center">
                      <Box
                        backgroundColor="neutralLightAlpha.300"
                        _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                        borderRadius="50%"
                        cursor="pointer"
                        padding={1}
                        onClick={(e) => {
                          e.stopPropagation();
                          // Move 10 second forward
                          let nextTenSeconds =
                            (playerRef.current?.getCurrentTime() || 0) + 10;
                          if (nextTenSeconds < clipDuration) {
                            playerRef.current?.seekTo(nextTenSeconds);
                            return;
                          }
                          playerRef.current?.seekTo(clipDuration);
                          setIsPlaying(false);
                        }}
                      >
                        <MdIcon name="Forward10" color="white" boxSize={6} />
                      </Box>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
            <LightMode>
              <Stack
                alignItems="center"
                justifyContent="center"
                direction={
                  orientation === 'landscape'
                    ? 'row'
                    : { base: 'row', md: 'column' }
                }
                p={{ base: 2, md: 0 }}
                mb={{ base: 0, md: 6 }}
                pb={{
                  base: 0,
                  md: 12,
                  // lg:
                  //   progressPercent === 100 && orientation === 'portrait'
                  //     ? 0
                  //     : 12,
                }}
                position="absolute"
                bottom={{ base: 'auto', md: 0 }}
                width={{ base: 'auto', md: '100%' }}
                height={{
                  base: 'auto',
                  // lg:
                  //   progressPercent === 100 && orientation === 'portrait'
                  //     ? '100%'
                  //     : 'auto',
                }}
              >
                {/* {mediaType !== 'image' && !embed && (
                  <Button
                    colorScheme="whiteAlpha"
                    icon="Replay"
                    size="sm"
                    fontSize={{ base: 'sm', md: 'sm' }}
                    sx={{ svg: { marginRight: { base: 0, md: 1.5 } } }}
                    onClick={(e) => {
                      e.stopPropagation();
                      playerRef.current?.seekTo(0);
                      setIsPlaying(true);
                    }}
                  >
                    <Text display={{ base: 'none', md: 'inline' }}>
                      Restart
                    </Text>
                  </Button>
                )} */}
                {isEditable && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditing(true);
                      setIsPlaying(false);
                    }}
                    colorScheme="whiteAlpha"
                    icon="Edit"
                    size="sm"
                    fontSize={{ base: 'sm', md: 'sm' }}
                    sx={{ svg: { marginRight: { base: 0, md: 1.5 } } }}
                    isLoading={uploadInProgress}
                    isDisabled={uploadInProgress}
                  >
                    <Text display={{ base: 'none', md: 'inline' }}>
                      Edit Media
                    </Text>
                  </Button>
                )}
              </Stack>
            </LightMode>
            {isEditable && srcHq && (
              <Flex
                position="absolute"
                top={0}
                right={0}
                cursor="pointer"
                px={4}
                py={4}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsPlaying(false);
                  // Download video
                  const filename = srcHq.substring(srcHq.lastIndexOf('/') + 1);
                  saveAs(srcHq, filename);
                }}
              >
                <MdIcon name="Download" color="white" boxSize={5} />
              </Flex>
            )}
          </Box>

          {indicators}
        </Flex>
      ) : (
        <Flex
          {...containerStyle}
          alignItems="flex-end"
          pb={{ base: 3, md: 7 }}
          pointerEvents="none"
        >
          {indicators}
        </Flex>
      )}
      {/* TODO:SUBTITLES */}
      {/* {subtitlesText && isPlaying ? (
        <Flex
          pointerEvents="none"
          position="absolute"
          bottom={16}
          left={0}
          right={0}
          zIndex={2}
        >
          <Text
            bg="rgba(0,0,0,0.5)"
            color="#fff"
            p={2}
            m={2}
            borderRadius="sm"
            fontWeight="semibold"
            textAlign="center"
          >
            {subtitlesText}
          </Text>
        </Flex>
      ) : null} */}
      {isEditable && (
        <>
          <Input
            ref={(e: HTMLInputElement | null) => {
              if (e) {
                fileInput.current = e;
              }
            }}
            type="file"
            accept={
              mediaType === 'audio'
                ? 'audio/wav,audio/mp3,audio/*'
                : 'video/mp4,video/x-m4v,video/*'
            }
            style={{ display: 'none' }}
            onChange={async (e) => {
              onUpload &&
                (await onUpload(
                  e,
                  id.toString(),
                  mediaType === 'audio' ? 'audio' : 'video'
                ));
              setIsEditing(false);
            }}
            onClick={(e) => e.stopPropagation()}
          />
          {!!recordingClip && onSaveClip && (
            <MediaRecorder
              isOpen
              clipType={recordingClip}
              onClose={() => setRecordingClip(null)}
              onSave={async (media) => {
                await onSaveClip(
                  recordingClip === 'video'
                    ? { videoBlob: media }
                    : { audioBlob: media }
                );
                setIsEditing(false);
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

const VideoClipsPlayer: React.FC<VideoClipsPlayerProps> = ({
  autoplay = true,
  autoPlayNext = false,
  currentStepIdx = 0,
  currentSessionStepId = null,
  loading = false,
  orientation = 'portrait',
  clips,
  isEditable = false,
  onUpload,
  qrBlob,
  embed,
  onSaveClip,
  onBlurBackground,
}) => {
  let startingStepIdx = currentStepIdx;
  if (startingStepIdx + 1 > clips.length) startingStepIdx = clips.length - 1;
  if (startingStepIdx < 0) startingStepIdx = 0;

  const [currentIdx, setCurrentIdx] = useState(startingStepIdx);
  const [savedSessionStepId, setSavedSessionStepId] =
    useState(currentSessionStepId);
  const [nextIdx, setNextIdx] = useState(startingStepIdx);

  // Update saved session step index when moving between session steps and if
  // our `currentStepIdx` prop changes, then navigate to that step (clip)
  useEffect(() => {
    // If the session step has changed, but other indicators show that this was the page's first render,
    // save the session step to the state without triggering a new clip animation
    if (
      ((currentStepIdx === 0 && currentIdx === 0) ||
        savedSessionStepId === null) &&
      currentSessionStepId !== savedSessionStepId
    ) {
      setSavedSessionStepId(currentSessionStepId);
      return;
    }

    // Don't do anything if the clip isn't set or hasn't changed
    if (currentStepIdx === undefined || currentStepIdx === currentIdx) {
      return;
    }

    // If the session step changes, change the current clip without a swiping animation, otherwise trigger
    // an animation when changing clips
    if (currentSessionStepId !== savedSessionStepId) {
      setNextIdx(0);
      setCurrentIdx(0);
      setSavedSessionStepId(currentSessionStepId);
    } else {
      // Set next index to trigger an animation in VideoPlayer, then set current index to switch clip
      setNextIdx(currentStepIdx);
      // Timeout to make sure the change of video clip source happens in the background while the sliding
      // animation between videos is happening over the top
      setTimeout(() => setCurrentIdx(currentStepIdx), 700);
    }
  }, [currentStepIdx, currentSessionStepId]);

  const orientationStyles = orientationStyleMapping[orientation];
  const currentStep = clips.length && clips[currentIdx];

  // Render a placeholder if no currentStep to render
  if (loading || !currentStep) {
    return (
      <Box {...orientationStyles} position="relative">
        <Flex
          {...containerStyle}
          backgroundColor="neutral.300"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="neutral.700" />
        </Flex>
      </Box>
    );
  }

  return (
    <VideoPlayer
      {...currentStep.data}
      id={currentStep.id}
      autoplay={autoplay}
      autoPlayNext={autoPlayNext}
      currentIdx={currentIdx}
      nextIdx={nextIdx}
      orientation={orientation}
      stepsTotal={clips.length}
      isEditable={isEditable}
      onUpload={onUpload}
      qrBlob={qrBlob}
      embed={embed}
      mediaType={currentStep.type}
      hasMedia={currentStep.hasMedia}
      onSaveClip={onSaveClip}
      onBlurBackground={onBlurBackground}
    />
  );
};

export default VideoClipsPlayer;
