import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  Card,
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  MdIcon,
} from '@workshop/ui';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

import { IVideoClip } from 'types/cms';
import { PERMISSION_SLUGS } from 'types/common';

import { videoUtils, analytics, capitalize } from 'utils';

import { PLATFORM } from 'constants/env';

import { useHasRole } from 'redux/selectors';
import { useHasPermission } from 'redux/selectors/organisation';

import { assistantActions } from 'redux/actions/common';

import { LabelTextArea, ConfirmModal } from 'components/Common';
import { VideoClipsPlayer } from 'components/VideoClipsPlayer';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveSummary?: (summary: string) => Promise<any>;
  summary?: string;
  video?: string;
  videoHq?: string;
  isDisabled?: boolean;
  autoplay?: boolean;
  isEditable?: boolean;
  onUpload?: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    mediaType?: 'video' | 'audio'
  ) => Promise<void>;
  onSaveClip?: (data: Partial<IVideoClip>) => Promise<void>;
  orientation?: 'portrait' | 'landscape' | 'square';
  mediaType?: 'video' | 'audio' | 'image' | 'text';
  clipId?: string | null;
  unitId?: string | null;
  qrBlob?: string;
}

const ModalVideo: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSaveSummary,
  summary: summaryProps,
  video = '',
  videoHq = '',
  isDisabled = false,
  autoplay = true,
  isEditable = false,
  onUpload,
  onSaveClip,
  orientation,
  mediaType = 'video',
  clipId,
  unitId,
  qrBlob,
}) => {
  const [summary, setSummary] = useState<string | undefined>(summaryProps);
  const [calcOrientation, setCalcOrientation] = useState<
    'portrait' | 'landscape'
  >('portrait');

  const [isUpdating, setIsUpdating] = useState(false);
  const [showSummaryConfirm, setShowSummaryConfirm] = useState(false);
  const [generatingSummary, setGeneratingSummary] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const saveDisabled = isDisabled;

  const dispatch = useDispatch();

  const hasEditPermissions = useHasPermission(
    PERMISSION_SLUGS.can_edit_content
  );
  const hasAdminRole = useHasRole('admin');

  useEffect(() => {
    const getVideoOrientation = async (video: string) => {
      const videoDimensions = await videoUtils.getVideoDimensions(video);
      if (videoDimensions.width > videoDimensions.height) {
        setCalcOrientation('landscape');
      } else {
        setCalcOrientation('portrait');
      }
    };
    getVideoOrientation(video);
  }, [video]);

  useEffect(() => {
    setSummary(summaryProps);
  }, [summaryProps]);

  const mediaOrientation = orientation || calcOrientation;

  const generateSummary = async () => {
    setErrorMessage('');
    setGeneratingSummary(true);
    let res = null;
    if (clipId) {
      res = await dispatch(
        assistantActions.generateClipSummary('clip', clipId)
      );
    } else if (unitId) {
      res = await dispatch(
        assistantActions.generateClipSummary('unit', unitId)
      );
    }
    if (res && res.error) {
      analytics.track('Clip Summary Failed');
      if (res.payload && 'normalizedErrors' in res.payload) {
        setErrorMessage(res.payload.normalizedErrors?.message as string);
      }
    } else if (res && res.payload && 'summary' in res.payload) {
      analytics.track('Clip Summary Generated');
      setSummary(res?.payload.summary);
      setIsTyping(true);
    }
    if (onSaveClip) {
      await onSaveClip({});
    }
    setGeneratingSummary(false);
  };

  const showAiButton = Boolean(
    (mediaType === 'video' || mediaType === 'audio') &&
      (clipId || unitId) &&
      video &&
      hasEditPermissions &&
      (PLATFORM === 'steppit' || (PLATFORM === 'workshop' && hasAdminRole))
  );

  return (
    <Modal
      size={
        !video && !isEditable
          ? 'xl'
          : mediaOrientation === 'landscape'
          ? '5xl'
          : 'sm'
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="lg"
        {...(mediaOrientation === 'portrait' ? { maxW: '45vh' } : {})}
      >
        <ModalBody padding={0}>
          <Card padding="defaultPadding" overflow="hidden" flexDir="column">
            {(video || isEditable) && (
              <Flex borderRadius="md" overflow="hidden">
                <VideoClipsPlayer
                  autoplay={autoplay}
                  autoPlayNext
                  isEditable={isEditable}
                  onUpload={onUpload}
                  onSaveClip={onSaveClip}
                  clips={[
                    {
                      type: mediaType,
                      hasMedia: true,
                      id: clipId || '',
                      data: {
                        autoplay: true,
                        clip: {
                          src: video,
                          srcHq: videoHq,
                        },
                      },
                    },
                  ]}
                  orientation={
                    mediaType === 'text' ? 'landscape' : mediaOrientation
                  }
                  {...(isEditable && qrBlob
                    ? {
                        qrBlob,
                      }
                    : {})}
                />
              </Flex>
            )}

            {onSaveSummary && (
              <>
                <Box position="relative" mt="defaultMargin">
                  {isTyping ? (
                    <Box
                      paddingTop={2}
                      paddingBottom={6}
                      marginBottom={3}
                      px={4}
                      borderWidth={1}
                      borderColor="gray.200"
                      borderRadius="md"
                    >
                      <Text lineHeight="short">
                        <Typist
                          avgTypingDelay={10}
                          cursor={{
                            show: true,
                            blink: true,
                            element: '|',
                            hideWhenDone: true,
                            hideWhenDoneDelay: 300,
                          }}
                          onTypingDone={() => setIsTyping(false)}
                        >
                          {summary}
                        </Typist>
                      </Text>
                    </Box>
                  ) : (
                    <LabelTextArea
                      id=""
                      name=""
                      label=""
                      autoResize
                      isDisabled={isDisabled || generatingSummary || isUpdating}
                      value={summary}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setSummary(e.currentTarget.value)
                      }
                      paddingBottom={6}
                      autoFocus
                      aiButton={
                        showAiButton
                          ? {
                              toolSlug: 'clipSummaries',
                              label: `Summarize ${capitalize(mediaType)} Clip`,
                              onProClick: () => {
                                if (!!summary) {
                                  setShowSummaryConfirm(true);
                                } else {
                                  generateSummary();
                                }
                              },
                              tooltip: `Let your assistant summarize this ${mediaType} clip into 1 or 2 simple sentences for you`,
                              isLoading: generatingSummary,
                              loadingText: `Summarizing ${mediaType}...`,
                              isOutline: !!summary,
                              isDisabled: isUpdating,
                              alwaysShow: true,
                              onSave: async () => null,
                            }
                          : undefined
                      }
                    />
                  )}

                  {showAiButton && (
                    <>
                      <ConfirmModal
                        body="This will replace your existing summary with one generated by your AI-powered assistant."
                        btnColor="green"
                        btnLabel="Yes, Generate"
                        title="Are You Sure?"
                        isLoading={generatingSummary}
                        isOpen={showSummaryConfirm}
                        onClose={() => setShowSummaryConfirm(false)}
                        onClick={() => generateSummary()}
                      />
                    </>
                  )}
                </Box>

                {errorMessage ? (
                  <Box bg="background.error" mt={2} p={4} borderRadius="md">
                    <Text color="text.error">{errorMessage}</Text>
                  </Box>
                ) : null}

                <Flex mt="defaultMargin" justifyContent="flex-end">
                  <Button
                    isLoading={isUpdating}
                    isDisabled={
                      saveDisabled ||
                      !summary ||
                      isUpdating ||
                      generatingSummary
                    }
                    onClick={async () => {
                      if (!summary) return;
                      setIsUpdating(true);
                      await onSaveSummary(summary);
                      setIsUpdating(false);
                      onClose();
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </>
            )}
            {summary && !onSaveSummary && !isEditable && (
              <Text pt={video ? 6 : 4} pb={1} px={2} textAlign="center">
                {summary}
              </Text>
            )}
            {!isEditable && (
              <Flex justifyContent="flex-end" mt={3}>
                <Button onClick={onClose} icon="Done" secondary w={10} />
              </Flex>
            )}
          </Card>
        </ModalBody>
        <Box
          position="absolute"
          pl={4}
          pb={4}
          pt={4}
          top="-40px"
          right={0}
          cursor="pointer"
          onClick={onClose}
          _hover={{ opacity: 0.8 }}
        >
          <MdIcon name="Close" boxSize="icon" color="white" />
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default ModalVideo;
