import { PLATFORM } from 'constants/env';
import { PLATFORM_DISPLAY_NAME } from 'constants/common';
import { AssistantToolSlug } from 'constants/assistant';
import { ResourceSlug } from 'constants/resources';

import { PermissionSlug, CurrentView, PERMISSION_SLUGS } from 'types/common';

import { OrganisationsScreen } from 'screens/admin';

// Common screens
import UnderConstruction from 'components/UnderConstruction';
import { HomeScreen } from 'screens/common/Home';
import { AcceptInvitation } from 'screens/common/AcceptInvitation';
import { LoginContainer } from 'screens/common/Login';
import { SignupScreen } from 'screens/common/Signup';
import { SettingsScreen } from 'screens/common/Settings';
import { PasswordReset } from 'screens/common/PasswordReset';
import { RoomScreen } from 'screens/common/Room';
import { ChannelScreen } from 'screens/common/Channel';
import { CheckoutScreen } from 'screens/common/Checkout';
import { LandingScreen, HowItWorksScreen } from 'screens/common/Landing';
import { ConfirmEmailScreen } from 'screens/common/ConfirmEmail';
import { ProSuccessScreen } from 'screens/common/ProSuccess';
import {
  TermsScreen,
  PrivacyScreen,
  CookiesScreen,
} from 'screens/common/Legal';
import { PlannerScreen } from 'screens/common/Plan';
import { CoursePlanScreen } from 'screens/common/Plan';
import { CourseLandingScreen } from 'screens/common/CourseLanding';
import { RedeemLicenseScreen } from 'screens/common/RedeemLicense';
import { RoadmapScreen } from 'screens/common/Roadmap';

// Bespoke screens
import { LeithsAcademyScreen } from 'screens/common/Bespoke/LeithsAcademy';
import { AcceleratorProgramScreen } from 'screens/common/Bespoke/AcceleratorProgram';

// Mentor screens
import { CohortAllocationScreen } from 'screens/common/CohortAllocation';
import { CourseCatalogueScreen } from 'screens/cms/CourseCatalogue';
import { CourseEditScreen } from 'screens/cms/CourseEdit';
import { CoursePreviewScreen } from 'screens/cms/CoursePreview';
import { SessionPreviewScreen } from 'screens/cms/SessionPreview';
import { EditMemberScreen } from 'screens/cms/EditMember';
import { ManageCohorts } from 'screens/cms/ManageCohorts';
import { MyOrganisation } from 'screens/cms/MyOrganisation';
import { AssistantScreen } from 'screens/cms/Assistant';
import { DigestScreen } from 'screens/cms/Digest';
import { PerformanceScreen } from 'screens/cms/Performance';
import { InsightsScreen } from 'screens/cms/Insights';
import { SessionEditScreen } from 'screens/cms/SessionEdit';
import { NewRoomScreen } from 'screens/cms/NewRoom';

// Learner screens
import { Dashboard } from 'screens/learner/Dashboard';
import { BrowseCourse } from 'screens/learner/BrowseCourse';
import { CourseCertificate } from 'screens/learner/CourseCertificate';
import { ClassActivity } from 'screens/learner/ClassActivity';
import { CourseOverview } from 'screens/learner/CourseOverview';
import { LibraryScreen, WishListScreen } from 'screens/learner/Library';
import { NewPost } from 'screens/learner/NewPost';
import { Profile } from 'screens/learner/Profile';
import { SessionScreen } from 'screens/learner/Session';
import { ViewPost } from 'screens/learner/ViewPost';
import { ScheduleScreen } from 'screens/learner/Schedule';
import { AssessmentScreen } from 'screens/learner/Assessment';

import { ORG_LANDING_PAGES } from 'constants/organisation';

export interface Route {
  /** Optional unique identifier for route */
  slug?: string;
  /** The component/screen to render */
  component: any;
  /** Name to display in the breadcrumbs */
  name: string;
  /** The route path */
  path: (...arg: any[]) => string;
  redirect?: string;
  /**
   * The path to find the relevant name
   * in the redux state, e.g: cms.course.title
   */
  stateNamePath?: string;
  /**
   * The path to find a relevant banner/image & org info
   * in the redux state, e.g: cms.course.image
   */
  stateBannerPath?: string;
  stateLogoPath?: string;
  stateFeatureImagePath?: string;
  stateOrgNamePath?: string;
  minimiseHeader?: boolean;
  /* Permissions required for accessing the route content */
  permissions?: { canView?: PermissionSlug[]; canEdit?: PermissionSlug[] };
  /** Link to display in the sidebar */
  sidebarLink?: {
    index: number;
    viewsDisplay: { view: CurrentView; disabled?: boolean }[];
  };
  sibarDefaultMinimised?: boolean;
  hideSidebar?: boolean;
  icon?: string;
  defaultView?: CurrentView;
  description?: string;
  scrollTopDisabled?: boolean;
  hideHeading?: boolean;
  useRouteNameInCrumb?: boolean;
  blockCookiePopup?: boolean;
  /*
   * Label to override route name in app heading –
   * use 'orgName' with stateOrgNamePath to use organisation name
   */
  label?: string;
  /*
   * Dismissable onboarding text to show beneath title
   */
  infoText?: string;
  /** Use template on global pages */
  useTemplate?: boolean;
  /** Remove route from isAuthenticated routes */
  noAuthOnly?: boolean;
  /** Array of AI assistant tools available on this route */
  assistantTools?: AssistantToolSlug[];
  /** Array of resources available on this route */
  resources?: ResourceSlug[];
  /** ID of InformationCard to be shown when isPro is true */
  proInfoCard?: string;
}

export interface Routes {
  /* Private routes - accessible only by admins when logged in */
  admin: { [key: string]: Route };
  /* Private routes - accessible by both mentors and learners when logged in */
  common: { [key: string]: Route };
  /* Private routes - accessible only by mentors when logged in */
  cms: { [key: string]: Route };
  /* Private routes - accessible only by learners when logged in */
  learner: { [key: string]: Route };
  /* Public routes - accessible by everyone only when logged out */
  public: { [key: string]: Route };
  /* Global routes - accessible by everyone */
  global: { [key: string]: Route };
}

const routes: Routes = {
  admin: {
    organisations: {
      path: () => '/admin/organisations',
      name: 'Organisations',
      component: OrganisationsScreen,
    },
  },
  common: {
    home: {
      path: () => '/',
      name: 'Home',
      icon: 'Home',
      sidebarLink: {
        viewsDisplay: [{ view: 'learner' }, { view: 'teacher' }],
        index: 0,
      },
      component: HomeScreen,
      hideSidebar: true,
    },
    about: {
      path: () => '/about',
      name: 'About',
      component: UnderConstruction,
      hideSidebar: true,
    },
    browse: {
      path: () => '/browse',
      name: 'Browse',
      component: UnderConstruction,
      hideSidebar: true,
    },
    download: {
      path: () => '/download',
      name: 'Download',
      component: UnderConstruction,
      hideSidebar: true,
    },
    inbox: {
      path: () => '/inbox',
      name: 'Inbox',
      component: UnderConstruction,
      hideSidebar: true,
    },
    myProfile: {
      path: () => '/my-profile',
      name: 'My Profile',
      component: Profile,
      hideSidebar: true,
    },
    settings: {
      path: () => '/settings',
      name: 'Settings',
      component: SettingsScreen,
      hideSidebar: true,
    },
    // === Bespoke landing pages ===
    lak: {
      path: () => ORG_LANDING_PAGES.lak,
      name: '',
      component: LeithsAcademyScreen,
      hideSidebar: true,
    },
  },
  learner: {
    dashboard: {
      path: () => '/next-up',
      name: 'Next Up',
      icon: 'TipsAndUpdates',
      component: Dashboard,
      sidebarLink: {
        index: 1,
        viewsDisplay: [{ view: 'learner' }],
      },
      defaultView: 'learner',
      description: 'See what I’m learning next.',
      infoText:
        'This is where you can find personalized suggestions of what to do next based on your course progress and your recent class activity.',
    },
    // activity: {
    //   path: () => '/activity',
    //   name: 'Activity',
    //   component: UnderConstruction,
    //   icon: 'Forum',
    //   sidebarLink: {
    //     viewsDisplay: [{ view: 'learner', disabled: true }],
    //     index: 3,
    //   },
    //   defaultView: 'learner',
    //   description: 'Catch up with the latest activity on my courses.',
    // },
    classActivity: {
      path: () => '/course/:courseSlug/activity',
      name: 'My Class',
      component: ClassActivity,
      stateBannerPath:
        'learner.courses.courses.detail.courseSlug.imageBannerMobile',
      stateLogoPath:
        'learner.courses.courses.detail.courseSlug.organisation.logo',
      defaultView: 'learner',
      sibarDefaultMinimised: true,
      infoText:
        'This is where you can chat with your classmates and view their posts. Your posts will also appear here, and you can receive feedback when your mentor leaves a comment.',
    },
    newDiscourseTopic: {
      path: () => '/course/:courseSlug/new-post',
      name: 'New Post',
      component: NewPost,
      defaultView: 'learner',
      sibarDefaultMinimised: true,
    },
    course: {
      path: (courseSlug = ':courseSlug') => `/course/${courseSlug}`,
      name: 'Course',
      label: 'orgName',
      component: CourseOverview,
      stateNamePath: 'learner.courses.courses.detail.courseSlug.title',
      stateBannerPath:
        'learner.courses.courses.detail.courseSlug.imageBannerMobile',
      stateLogoPath:
        'learner.courses.courses.detail.courseSlug.organisation.logo',
      stateOrgNamePath:
        'learner.courses.courses.detail.courseSlug.organisation.name',
      defaultView: 'learner',
      sibarDefaultMinimised: true,
      infoText:
        'This is where you can find personalized suggestions of what to do next in this course based on your progress and recent activity in your class.',
    },
    courseBrowse: {
      path: (courseSlug = ':courseSlug') => `/course/${courseSlug}/browse`,
      name: 'Browse Course',
      component: BrowseCourse,
      stateBannerPath:
        'learner.courses.courses.detail.courseSlug.imageBannerMobile',
      stateLogoPath:
        'learner.courses.courses.detail.courseSlug.organisation.logo',
      defaultView: 'learner',
      sibarDefaultMinimised: true,
      infoText:
        'This is where you can explore your course content. From here, you can quickly navigate to your sessions and revisit any you may have already completed.',
    },
    courseCertificate: {
      path: () => '/course/:courseSlug/certificate',
      name: 'Certificate',
      component: CourseCertificate,
      stateBannerPath:
        'learner.courses.courses.detail.courseSlug.imageBannerMobile',
      stateLogoPath:
        'learner.courses.courses.detail.courseSlug.organisation.logo',
      defaultView: 'learner',
      sibarDefaultMinimised: true,
      infoText:
        'This is where you can view your progress toward earning your certificate of completion. Once you have met all the criteria, you can download your certificate from here.',
    },
    library: {
      path: () => '/my-classes',
      name: 'My Classes',
      component: LibraryScreen,
      icon: 'Workspaces',
      sidebarLink: {
        viewsDisplay: [{ view: 'learner' }],
        index: 2,
      },
      defaultView: 'learner',
      description: 'Jump into a session or catch up with my class.',
      infoText:
        'Here you will find a list of the classes you are enrolled on, where you can click through to access each course.',
    },
    schedule: {
      path: () => '/schedule',
      name: 'Schedule',
      component: ScheduleScreen,
      icon: 'Today',
      sidebarLink: {
        viewsDisplay: [{ view: 'learner' }],
        index: 3,
      },
      defaultView: 'learner',
      description: 'Plan my learning schedule.',
      infoText:
        'This is where you can view your scheduled sessions, schedule in upcoming sessions and view your past, present and upcoming classes.',
    },
    module: {
      path: (courseSlug = ':courseSlug', moduleId = ':moduleId') =>
        `/course/${courseSlug}/${moduleId}`,
      name: 'Session',
      component: SessionScreen,
      minimiseHeader: true,
      stateNamePath: 'learner.courses.modules.(id === moduleId).title',
      stateBannerPath:
        'learner.courses.courses.detail.courseSlug.imageBannerMobile',
      stateLogoPath:
        'learner.courses.courses.detail.courseSlug.organisation.logo',
      defaultView: 'learner',
      sibarDefaultMinimised: true,
      scrollTopDisabled: true,
    },
    wishlist: {
      path: () => '/library/wishlist',
      name: 'Wishlist',
      component: WishListScreen,
      defaultView: 'learner',
    },
    viewPost: {
      path: (courseSlug = ':courseSlug', topicId = ':topicId') =>
        `/course/${courseSlug}/activity/${topicId}`,
      name: 'Post',
      component: ViewPost,
      defaultView: 'learner',
      sibarDefaultMinimised: true,
    },
    genericViewPost: {
      path: (topicId = ':topicId') => `/activity/${topicId}`,
      name: 'Post',
      component: ViewPost,
      defaultView: 'learner',
      sibarDefaultMinimised: true,
    },
    assessment: {
      path: (courseSlug = ':courseSlug') => `/assessment/${courseSlug}`,
      stateNamePath: 'learner.courses.courses.detail.courseSlug.title',
      name: 'Assessment Active',
      component: AssessmentScreen,
      hideSidebar: true,
    },
    assessmentModule: {
      slug: 'assessmentModule',
      path: (courseSlug = ':courseSlug', moduleId = ':moduleId') =>
        `/assessment/${courseSlug}/${moduleId}`,
      name: 'Assessment Session',
      component: SessionScreen,
      minimiseHeader: true,
      stateNamePath: 'learner.courses.modules.(id === moduleId).title',
      hideSidebar: true,
      scrollTopDisabled: true,
    },
  },
  cms: {
    // TODO: Finish this screen
    // insights: {
    //   path: () => '/t/insights',
    //   name: 'Insights',
    //   icon: 'Timelapse',
    //   component: InsightsScreen,
    //   sidebarLink: {
    //     index: 4,
    //     viewsDisplay: [{ view: 'teacher' }],
    //   },
    //   defaultView: 'teacher',
    //   description: 'Track and analyze how students are using my courses.',
    // },
    catalogue: {
      path: () => '/t/content',
      name: 'Content',
      component: CourseCatalogueScreen,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_content],
        canEdit: [PERMISSION_SLUGS.can_edit_content],
      },
      icon: PLATFORM === 'steppit' ? 'BubbleChart' : 'PlayLesson',
      sidebarLink: {
        viewsDisplay: [{ view: 'teacher' }],
        index: 1,
      },
      defaultView: 'teacher',
      description: "View my channel's sessions and courses.",
      infoText:
        "This is where you can start creating a new course or session, jump into content you're working on or tweak content you've already published.",
      assistantTools: ['proCourses'],
      resources: ['planCourse'],
      proInfoCard: 'pro_content',
    },
    classes: {
      path: () => '/t/classes/',
      name: 'Classes',
      icon: 'GroupWork',
      component: CohortAllocationScreen,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_classes],
        canEdit: [PERMISSION_SLUGS.can_edit_classes],
      },
      sidebarLink: {
        index: 2,
        viewsDisplay: [{ view: 'teacher' }],
      },
      defaultView: 'teacher',
      description:
        "Manage my classes and check in with the learners I'm teaching.",
      infoText:
        'This is where you can create and manage classes to let learners join your courses.',
      proInfoCard: 'pro_classes',
      resources: [
        'creatingClasses',
        'enrollingLearners',
        'mentoringClasses',
        'certificates',
        'learnerExperience',
      ],
    },
    editCourse: {
      path: (courseId = ':courseId') => `/t/course/${courseId}`,
      name: 'Course',
      stateNamePath: 'cms.course.course.courseId.title',
      component: CourseEditScreen,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_content],
        canEdit: [PERMISSION_SLUGS.can_edit_content],
      },
      defaultView: 'teacher',
      assistantTools: ['courseTextBoxes'],
      resources: ['defineSession', 'defineUnit'],
    },
    previewCourse: {
      path: (courseId = ':courseId') => `/t/course-preview/${courseId}`,
      name: 'Course Preview',
      stateNamePath: 'learner.courses.courses.detail.(id === courseId).title',
      stateBannerPath:
        'learner.courses.courses.detail.(id === courseId).imageBannerMobile',
      stateLogoPath:
        'learner.courses.courses.detail.(id === courseId).organisation.logo',
      component: CoursePreviewScreen,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_content],
        canEdit: [PERMISSION_SLUGS.can_edit_content],
      },
      defaultView: 'teacher',
    },
    previewSession: {
      path: (courseId = ':courseId', moduleId = ':moduleId') =>
        `/t/course-preview/${courseId}/${moduleId}`,
      name: 'Session Preview',
      stateNamePath: 'learner.courses.modules.(id === moduleId).title',
      component: SessionPreviewScreen,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_content],
        canEdit: [PERMISSION_SLUGS.can_edit_content],
      },
      defaultView: 'teacher',
      sibarDefaultMinimised: true,
    },
    createCourse: {
      path: () => '/t/create-course',
      name: 'Create Course',
      component: UnderConstruction,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_content],
        canEdit: [PERMISSION_SLUGS.can_edit_content],
      },
      defaultView: 'teacher',
    },
    inviteMember: {
      path: () => '/t/admin/invite-member',
      name: 'Invite Member',
      component: EditMemberScreen,
      defaultView: 'teacher',
    },
    myOrganisation: {
      path: () => '/t/admin',
      name: 'Admin',
      component: MyOrganisation,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_organisation],
        canEdit: [
          PERMISSION_SLUGS.can_edit_billing_details,
          PERMISSION_SLUGS.can_edit_members,
          PERMISSION_SLUGS.can_edit_organisation,
        ],
      },
      icon: 'Shield',
      sidebarLink: {
        viewsDisplay: [{ view: 'teacher' }],
        index: 6,
      },
      defaultView: 'teacher',
      description: 'Manage my channel details and team members.',
      proInfoCard: 'pro_integrations',
    },
    assistant: {
      slug: 'assistant',
      path: () => '/t/assistant',
      name: 'Assistant',
      component: AssistantScreen,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_organisation],
        canEdit: [PERMISSION_SLUGS.can_edit_organisation],
      },
      icon: 'AutoAwesome',
      sidebarLink:
        PLATFORM === 'steppit'
          ? {
              viewsDisplay: [{ view: 'teacher' }],
              index: 5,
            }
          : undefined,
      defaultView: 'teacher',
      description: 'Train and get insights from my assistant.',
      proInfoCard: 'pro_assistant',
      resources: ['trainingAssistant'],
    },
    digest: {
      path: (digestSlug = ':digestSlug') => `/t/digest/${digestSlug}`,
      name: 'Digest',
      component: DigestScreen,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_organisation],
        canEdit: [PERMISSION_SLUGS.can_edit_organisation],
      },
      defaultView: 'teacher',
      stateNamePath: 'assistant.digests.digestSlug.json.summary.headline',
      hideHeading: true,
    },
    // TODO: Finish this screen
    // performance: {
    //   path: () => '/t/performance',
    //   name: 'Performance',
    //   icon: 'Insights',
    //   permissions: {
    //     canView: [PERMISSION_SLUGS.can_view_performance],
    //   },
    //   component: PerformanceScreen,
    //   sidebarLink: {
    //     index: 3,
    //     viewsDisplay: [{ view: 'teacher' }],
    //   },
    //   defaultView: 'teacher',
    //   description: 'Find out when, where and how my courses are selling.',
    // },
    teamMember: {
      path: (memberId = ':memberId') => `/t/admin/edit-member/${memberId}`,
      name: 'Edit Member',
      component: EditMemberScreen,
      defaultView: 'teacher',
    },
    session: {
      path: (courseId = ':courseId', sessionId = ':sessionId') =>
        `/t/course/${courseId}/session/${sessionId}`,
      name: 'Session',
      stateNamePath: 'cms.session.session.sessionId.title',
      component: SessionEditScreen,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_content],
        canEdit: [PERMISSION_SLUGS.can_edit_content],
      },
      defaultView: 'teacher',
      sibarDefaultMinimised: true,
      assistantTools: ['courseTextBoxes', 'clipSummaries'],
      resources: [
        'planSession',
        'stepByStep',
        'uploadingContent',
        'equipment',
        'defineSession',
      ],
    },
    standaloneSession: {
      path: (sessionId = ':sessionId') => `/t/session/${sessionId}`,
      name: 'Session',
      stateNamePath: 'cms.session.session.sessionId.title',
      component: SessionEditScreen,
      permissions: {
        canView: [PERMISSION_SLUGS.can_view_content],
        canEdit: [PERMISSION_SLUGS.can_edit_content],
      },
      defaultView: 'teacher',
      sibarDefaultMinimised: true,
      assistantTools: ['courseTextBoxes', 'clipSummaries'],
      resources: [
        'planSession',
        'stepByStep',
        'uploadingContent',
        'equipment',
        'defineSession',
      ],
    },
    students: {
      path: () =>
        PLATFORM === 'workshop'
          ? '/t/classes/my-students'
          : '/t/classes/my-learners',
      name: PLATFORM === 'workshop' ? 'My Students' : 'My Learners',
      icon: 'People',
      component: ManageCohorts,
      defaultView: 'teacher',
      description: 'Chat with my learners and check their latest posts.',
      infoText:
        "This is where you can view your learners' posts to give feedback, chat with your classes or message learners privately.",
      resources: [
        'creatingClasses',
        'enrollingLearners',
        'mentoringClasses',
        'certificates',
        'learnerExperience',
      ],
    },
    cmsViewPost: {
      path: (topicId = ':topicId') => `/t/activity/${topicId}`,
      name: 'Post',
      component: ViewPost,
      defaultView: 'teacher',
      sibarDefaultMinimised: true,
    },
    createRoom: {
      path: () => `/t/room/new`,
      name: 'New Room',
      component: NewRoomScreen,
      permissions: {
        canView: [PERMISSION_SLUGS.can_create_rooms],
        canEdit: [PERMISSION_SLUGS.can_create_rooms],
      },
      defaultView: 'teacher',
    },
    editChannel: {
      path: (handle = ':handle') => `/t/channel`,
      name: 'Channel',
      icon: 'Store',
      component: ChannelScreen,
      permissions: {
        canEdit: [PERMISSION_SLUGS.can_edit_organisation],
      },
      ...(PLATFORM === 'steppit'
        ? {
            sidebarLink: {
              index: 3,
              viewsDisplay: [{ view: 'teacher' }],
            },
          }
        : {}),
      defaultView: 'teacher',
      description: 'View and edit my public channel page.',
      hideHeading: true,
      assistantTools: ['channelPage'],
      resources: ['channelSetup'],
    },
  },
  public: {
    landing: {
      path: () => '/',
      name: PLATFORM_DISPLAY_NAME,
      component: LandingScreen,
      noAuthOnly: true,
    },
    howItWorks: {
      path: () => '/how-it-works',
      name: 'How It Works',
      component: HowItWorksScreen,
    },
    login: {
      path: () => '/login',
      name: 'Login',
      component: LoginContainer,
    },
    forgotPassword: {
      path: () => '/forgot-password',
      name: 'Forgot Password',
      component: PasswordReset,
    },
    signup: {
      path: () => '/signup',
      name: 'Sign Up',
      component: SignupScreen,
    },
  },
  global: {
    resetPassword: {
      path: (uidb36?: string) =>
        uidb36
          ? `/password/reset/key/${uidb36}-set-password`
          : '/password/reset/key/:uidb36-:keyId',
      name: 'Reset Password',
      component: PasswordReset,
    },
    planner: {
      path: () => '/plan',
      name: 'Plan a Course',
      component: PlannerScreen,
    },
    coursePlan: {
      path: (planSlug = ':planSlug') => `/plan/${planSlug}`,
      name: 'Course Plan',
      component: CoursePlanScreen,
    },
    acceptInvitation: {
      path: () => '/accept-invite/:invitationKey',
      name: 'Accept Invitation',
      component: AcceptInvitation,
    },
    confirmEmail: {
      path: (key = ':key') => `/confirm-email/${key}`,
      name: 'Email Confirmed',
      component: ConfirmEmailScreen,
    },
    proSuccess: {
      path: () => `/upgrade-success`,
      name: 'Upgrade Success',
      component: ProSuccessScreen,
    },
    room: {
      path: (roomId = ':roomId') => `/room/${roomId}`,
      name: 'Live Room',
      component: RoomScreen,
    },
    channel: {
      path: (handle = ':handle') => `/@${handle}`,
      name: 'Channel',
      component: ChannelScreen,
      useTemplate: true,
      hideHeading: true,
    },
    checkout: {
      path: (courseSlug = ':courseSlug') => `/checkout/${courseSlug}`,
      name: 'Checkout',
      component: CheckoutScreen,
      sibarDefaultMinimised: true,
      useTemplate: true,
    },
    courseLanding: {
      path: (courseSlug = ':courseSlug') => `/c/${courseSlug}`,
      name: 'Course',
      stateNamePath: 'learner.courses.courses.detail.courseSlug.title',
      component: CourseLandingScreen,
      useTemplate: true,
      hideHeading: true,
    },
    publicCourse: {
      path: (courseSlug = ':courseSlug') => `/c/${courseSlug}/browse`,
      name: 'Browse Course',
      component: BrowseCourse,
      stateNamePath: 'learner.courses.courses.detail.courseSlug.title',
      stateFeatureImagePath:
        'learner.courses.courses.detail.courseSlug.imageLandscapeMobile',
      sibarDefaultMinimised: true,
      useTemplate: true,
      useRouteNameInCrumb: true,
    },
    publicSession: {
      path: (courseSlug = ':courseSlug', moduleId = ':moduleId') =>
        `/c/${courseSlug}/browse/${moduleId}`,
      name: 'Session',
      component: SessionPreviewScreen,
      minimiseHeader: true,
      stateNamePath: 'learner.courses.modules.(id === moduleId).title',
      sibarDefaultMinimised: true,
      scrollTopDisabled: true,
      useTemplate: true,
    },
    publicStandaloneSession: {
      path: (courseSlug = ':courseSlug') => `/s/${courseSlug}`,
      name: 'Session',
      component: SessionPreviewScreen,
      minimiseHeader: true,
      stateNamePath: 'learner.courses.courses.detail.courseSlug.title',
      sibarDefaultMinimised: true,
      scrollTopDisabled: true,
      useTemplate: true,
    },
    embedStandaloneSession: {
      path: (courseSlug = ':courseSlug') => `/embed/s/${courseSlug}`,
      name: 'Session',
      component: SessionPreviewScreen,
      minimiseHeader: true,
      stateNamePath: 'learner.courses.courses.detail.courseSlug.title',
      sibarDefaultMinimised: true,
      scrollTopDisabled: true,
      blockCookiePopup: true,
    },
    redeemLicense: {
      path: (licenseSlug = ':licenseSlug') => `/redeem-license/${licenseSlug}`,
      name: 'Redeem License',
      component: RedeemLicenseScreen,
    },
    acceleratorProgram: {
      path: () => '/accelerator-program',
      name: 'Accelerator Program',
      component: AcceleratorProgramScreen,
      hideSidebar: true,
      hideHeading: true,
      useTemplate: true,
    },
    roadmap: {
      path: () => `/roadmap`,
      name: 'Roadmap',
      component: RoadmapScreen,
    },
    terms: {
      path: () => '/terms',
      name: 'Terms of Service',
      component: TermsScreen,
      sibarDefaultMinimised: true,
      useTemplate: true,
    },
    privacy: {
      path: () => '/privacy',
      name: 'Privacy Policy',
      component: PrivacyScreen,
      sibarDefaultMinimised: true,
      useTemplate: true,
    },
    cookies: {
      path: () => '/cookies',
      name: 'Cookie Policy',
      component: CookiesScreen,
      sibarDefaultMinimised: true,
      useTemplate: true,
    },
  },
};

export type HomeScreenRoute = 'learner' | 'teacher';

export type HomeScreenRoutes = { [key in HomeScreenRoute]: Route[] };

export const HOME_SCREEN_ROUTES: HomeScreenRoutes = {
  learner: [
    routes.learner.dashboard,
    routes.learner.library,
    routes.learner.schedule,
    // routes.learner.activity,
  ],
  teacher: [
    routes.cms.catalogue,
    routes.cms.classes,
    // routes.cms.insights,
    // routes.cms.performance,
    ...(PLATFORM === 'steppit'
      ? [routes.cms.editChannel, routes.cms.assistant]
      : []),
    routes.cms.myOrganisation,
  ],
};

export default routes;
