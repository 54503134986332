import React from 'react';
import { useDispatch } from 'react-redux';
import { animated, Transition } from 'react-spring';
import { FaGem } from 'react-icons/fa';

import { CLIP_NAME } from 'constants/common';
import { PLATFORM } from 'constants/env';

import {
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  AlertProps,
  Flex,
  Box,
  Button,
  Collapse,
  Image,
  MdIcon,
} from '@workshop/ui';

import { profileActions } from 'redux/actions/common';
import { useDismissedInformationCards } from 'redux/selectors/user';

export interface Information {
  title?: string;
  description: string;
  status?: 'info' | 'warning' | 'success' | 'error';
  overlay?: boolean;
  variants?: { [key: string]: string };
  pro?: boolean;
  thumbnail?: string;
}

// TODO :
// These announcements should live in a backend model and
// should be retreievedvia the API
const INFORMATIONS: { [key: string]: Information } = {
  home_screen: {
    // title: 'Info Box Title',
    description:
      'Easily find your way around the platform and return to this page at any time by looking out for the Home button.',
    // variants: {
    //   owner: 'Some different text',
    // },
  },
  learner_home_screen: {
    description: 'Head to My Classes to find your course and get started!',
  },
  account_screen: {
    description: 'This is where you can customize your channel details.',
  },
  team_screen: {
    description:
      "This is where you can invite new members to your channel's team and edit team members' roles and permissions.",
  },
  agreements_screen: {
    description:
      'This is where you can create and manage agreements between your channel and another. Agreements can be made to assign a number of course enrollments to another channel in bulk.',
  },
  payments_screen: {
    description:
      'This is where you can set up your account to receive payment when you sell class places.',
  },
  course_preview_section: {
    title: 'Course Previews',
    description:
      'Here you can preview courses from an agreement between your channel and another. This lets you view the course from the perspective of a learner.',
    overlay: true,
  },
  browse_course_scheduled_cohort: {
    description:
      'Together, you and your classmates gain access to your course week by week. Once each week unlocks, you can revisit any previous sessions you may have already completed at any time during your course access period.',
    overlay: true,
  },
  browse_course_open_cohort: {
    description:
      'Together, you and your classmates have access to all course content right from the start. Follow along with the content in order, or jump around in whatever way suits you. Once you have completed a session, you can revisit it at any time from here throughout your access period.',
    overlay: true,
  },
  classes_active_license: {
    description:
      'Having an active bulk enrollment agreement allows you to enroll learners on courses from another channel. Create a new class to get started.',
    overlay: true,
  },
  extended_session_step: {
    title: 'Extended Sessions',
    description: `For extended sessions there only needs to be a single step, with a single ${CLIP_NAME}`,
  },
  unassigned_clips: {
    title: `Unassigned Media`,
    description: `Media uploaded using the batch upload functionality will appear here. Assign each ${CLIP_NAME} to a step and press save to save all of your changes at once.`,
  },
  copywriter_overview: {
    title: 'Writing Summaries',
    description:
      'As a copywriter, you can watch videos and write summaries in the sections below.',
  },
  session_builder: {
    description: `This is your session builder. Use the Outline View to lay out and plan your session, then head to the Stepper View when you're ready to start teaching one step at a time.`,
    overlay: true,
  },
  session_details: {
    description:
      'Here you can edit and update your session thumbnail image. You can also add some optional session details, including an estimated duration time, description and checklist of items learners should have at the ready before they start.',
    overlay: true,
  },
  session_to_do: {
    description:
      'You have a to-do list for each session so that you can keep track of what’s left to do before you’re ready to publish and share it with the world!',
    overlay: true,
  },
  pro_content: {
    title: 'Extended Courses & Pro Planner',
    description:
      'You can now add unlimited units to your courses, and you can use the Pro Course Planner to auto-generate a plan tailored to you.',
    pro: true,
  },
  pro_classes: {
    title: 'Private Classes',
    description:
      'With Pro, you can create private classes for your courses and use enrollments to add learners via email invites and integrations.',
    pro: true,
  },
  pro_assistant: {
    title: 'Your AI Assistant',
    description:
      'Your assistant is here! Now you can generate content perfectly tailored to you.',
    // 'Your assistant is here! Now you can generate content tailored to you and get bespoke insights every week in your digests...',
    pro: true,
  },
  on_accelerator_program: {
    title: 'Our Pro accelerator program is on the way!',
    description: "We'll be in touch soon with your class details 👌",
    overlay: true,
  },
};

export const DYNAMIC_INFORMATION_IDS = {
  substitute_mentor: 'substitute_mentor__web_app',
};

interface Props extends AlertProps {
  id: string;
  variant?: string;
  information?: Information | null;
  onDismiss?: () => void;
  showInfo?: boolean;
  isDismissable?: boolean;
  cta?: React.ReactElement;
}

const InformationCard: React.FC<Props> = ({
  id,
  variant,
  information: informationProp,
  onDismiss,
  showInfo,
  isDismissable = true,
  cta,
  ...props
}) => {
  const dispatch = useDispatch();

  const dismissedInformationCards = useDismissedInformationCards();

  const shouldShow = !dismissedInformationCards.includes(id) || showInfo;

  const onClick = () => {
    onDismiss && onDismiss();
    dispatch(
      profileActions.updateUserProfile(
        {
          dismissedInformationCards: [...dismissedInformationCards, id],
        },
        { toast: { success: false, error: false } }
      )
    );
  };

  const information = informationProp || INFORMATIONS[id] || null;

  // If no information object has been provided or found using the ID, return nothing
  if (!information) return null;

  if (PLATFORM !== 'steppit' && information.pro) return null;

  const { description, variants } = information;

  // If a `variant` prop has been provided and the information object
  // contains `variants`, then see if we can use the `variant` prop to
  // extract a specific message, falling back to the default `description`
  // if not.
  const message =
    variant && variants ? variants[variant] || description : description;

  return (
    <Transition
      items={shouldShow}
      from={{ opacity: 0.8, scale: 0.98 }}
      enter={{ opacity: 1, scale: 1 }}
      leave={{ opacity: 0.8, scale: 0.98 }}
      config={{ tension: 200, friction: 8 }}
    >
      {(styles, item) => (
        <animated.div style={styles}>
          {information ? (
            <Collapse in={shouldShow}>
              <Alert
                status={
                  information.pro ? 'warning' : information.status || 'info'
                }
                borderRadius="md"
                {...props}
              >
                {information.overlay && (
                  <Box
                    position="absolute"
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    backgroundColor="background.defaultXTransparent"
                  />
                )}
                {information.thumbnail ? null : information.pro ? (
                  <Box
                    w={5}
                    h={6}
                    mr={3}
                    color="orange.500"
                    _dark={{ color: 'orange.300' }}
                  >
                    <FaGem />
                  </Box>
                ) : (
                  <AlertIcon position="relative" />
                )}
                <Flex
                  position="relative"
                  flex={1}
                  alignItems={{
                    // base: 'none',
                    base: 'none',
                    md: 'center',
                  }}
                  flexDirection={{
                    // base: 'column',
                    base: 'column',
                    md: 'row',
                  }}
                >
                  {information.thumbnail ? (
                    <Flex
                      position="relative"
                      alignItems="center"
                      justifyContent="center"
                      mr={{ base: 0, md: 3 }}
                      mb={{ base: 3, md: 0 }}
                      ml={{ base: 0, md: -0.5 }}
                      alignSelf={{
                        // base: 'none',
                        base: 'center',
                        md: 'flex-start',
                      }}
                    >
                      <Image
                        width={{ base: '100%', sm: '240px', md: '120px' }}
                        htmlHeight="80"
                        htmlWidth="120"
                        objectFit="cover"
                        borderRadius="md"
                        bg="background.tint3"
                        src={information.thumbnail}
                        alt={information.title}
                      />
                      <Box
                        position="absolute"
                        bg="background.default"
                        fontSize="2xl"
                        borderRadius="full"
                      >
                        <MdIcon name="PlayCircle" color="common.primary" />
                      </Box>
                    </Flex>
                  ) : null}
                  <Box
                    flex={1}
                    textAlign={{
                      // base: 'left',
                      base: information.thumbnail ? 'center' : 'left',
                      md: 'left',
                    }}
                  >
                    {information.title ? (
                      <AlertTitle fontWeight="bold">
                        {information.title}
                      </AlertTitle>
                    ) : null}
                    <AlertDescription display="block">
                      {message}
                    </AlertDescription>
                  </Box>
                  {isDismissable ? (
                    <Flex
                      flex={{ base: 1, md: 'none' }}
                      justifyContent={{
                        // base: 'flex-end',
                        base: information.thumbnail ? 'center' : 'flex-end',
                        md: 'flex-end',
                      }}
                    >
                      <Button
                        size="xs"
                        icon="ThumbUp"
                        ml={{ base: 0, md: 4 }}
                        mt={{ base: 2, md: 0 }}
                        onClick={onClick}
                        colorScheme={information.pro ? 'orange' : 'blue'}
                      >
                        Got it
                      </Button>
                    </Flex>
                  ) : cta ? (
                    <Flex
                      flex={{ base: 1, md: 'none' }}
                      justifyContent={{
                        // base: 'flex-end',
                        base: information.thumbnail ? 'center' : 'flex-end',
                        md: 'flex-end',
                      }}
                    >
                      {cta}
                    </Flex>
                  ) : null}
                </Flex>
              </Alert>
            </Collapse>
          ) : null}
        </animated.div>
      )}
    </Transition>
  );
};

export default InformationCard;
