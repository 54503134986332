import { UserDetailsSchema } from 'redux/schemas/learner';
import { callAPI } from 'utils';
import API from 'constants/api';
import { decamelizeKeys } from 'humps';

import {
  FetchUserProfileAction,
  UpdateUserProfileAction,
  UserDetails,
  RedeemCouponAction,
  FetchUserCouponsAction,
  FetchUserSubscriptionsAction,
  FetchPaymentSettingsAction,
  UpdatePaymentSettingsAction,
  DeleteAccountAction,
  ResendVerificationEmailAction,
  CancelEmailChangeAction,
  ToastMetaParams,
} from 'types/common';
import { ProfileAT } from 'redux/actionTypes/common';

export const fetchUserProfile = () => {
  return callAPI<FetchUserProfileAction>({
    types: [
      ProfileAT.FETCH_USER_PROFILE_REQUEST,
      ProfileAT.FETCH_USER_PROFILE_SUCCESS,
      ProfileAT.FETCH_USER_PROFILE_FAILURE,
    ],
    endpoint: API.account.userProfile,
    method: 'GET',
    schema: UserDetailsSchema,
  });
};

export const updateUserProfile = (
  data: Partial<
    Pick<
      UserDetails,
      'dismissedInformationCards' | 'email' | 'name' | 'userType'
    >
  >,
  meta?: { toast: ToastMetaParams }
) => {
  return callAPI<UpdateUserProfileAction>({
    types: [
      ProfileAT.UPDATE_USER_PROFILE_REQUEST,
      ProfileAT.UPDATE_USER_PROFILE_SUCCESS,
      ProfileAT.UPDATE_USER_PROFILE_FAILURE,
    ],
    endpoint: API.account.userProfile,
    method: 'PATCH',
    schema: UserDetailsSchema,
    body: decamelizeKeys(data),
    ...(meta ? { meta } : {}),
  });
};

const fetchUserCoupons = () => {
  return callAPI<FetchUserCouponsAction>({
    types: [
      ProfileAT.FETCH_USER_COUPONS_REQUEST,
      ProfileAT.FETCH_USER_COUPONS_SUCCESS,
      ProfileAT.FETCH_USER_COUPONS_FAILURE,
    ],
    endpoint: API.account.userCoupons,
    method: 'GET',
  });
};

const redeemCoupon = (coupon: { code: string }) => {
  return callAPI<RedeemCouponAction>({
    types: [
      ProfileAT.REDEEM_COUPON_REQUEST,
      ProfileAT.REDEEM_COUPON_SUCCESS,
      ProfileAT.REDEEM_COUPON_FAILURE,
    ],
    endpoint: API.account.redeemCoupon,
    method: 'POST',
    body: decamelizeKeys({ couponCode: coupon.code }),
    meta: {
      toast: {
        success: 'Coupon successfully redeemed',
        error: 'Invalid coupon code',
      },
    },
  });
};

const fetchSubscriptions = () => {
  return callAPI<FetchUserSubscriptionsAction>({
    types: [
      ProfileAT.FETCH_USER_SUBSCRIPTIONS_REQUEST,
      ProfileAT.FETCH_USER_SUBSCRIPTIONS_SUCCESS,
      ProfileAT.FETCH_USER_SUBSCRIPTIONS_FAILURE,
    ],
    endpoint: API.account.subscriptions,
    method: 'GET',
  });
};

const fetchPaymentSettings = () => {
  return callAPI<FetchPaymentSettingsAction>({
    types: [
      ProfileAT.FETCH_PAYMENT_SETTINGS_REQUEST,
      ProfileAT.FETCH_PAYMENT_SETTINGS_SUCCESS,
      ProfileAT.FETCH_PAYMENT_SETTINGS_FAILURE,
    ],
    endpoint: API.account.paymentSettings,
    method: 'GET',
  });
};

const updatePaymentSettings = (id: string) => {
  return callAPI<UpdatePaymentSettingsAction>({
    types: [
      ProfileAT.UPDATE_PAYMENT_SETTINGS_REQUEST,
      ProfileAT.UPDATE_PAYMENT_SETTINGS_SUCCESS,
      ProfileAT.UPDATE_PAYMENT_SETTINGS_FAILURE,
    ],
    endpoint: API.account.paymentSettings,
    method: 'POST',
    body: { paymentMethod: id },
    meta: {
      toast: {
        success: 'Successfully updated card details',
      },
    },
  });
};

type DecamelizedParams = {
  [key: string]: 'DELETE' | 'DEACTIVATE';
};

const deleteAccount = (requestType: 'DELETE' | 'DEACTIVATE') => {
  const body = decamelizeKeys<DecamelizedParams>({ requestType });
  return callAPI<DeleteAccountAction>({
    types: [
      ProfileAT.DELETE_ACCOUNT_REQUEST,
      ProfileAT.DELETE_ACCOUNT_SUCCESS,
      ProfileAT.DELETE_ACCOUNT_FAILURE,
    ],
    endpoint: API.auth.deleteAccount,
    method: 'PATCH',
    body,
    meta: {
      toast: {
        success:
          requestType === 'DELETE'
            ? 'Your account has been deleted'
            : 'Your account has been deactivated',
        error: true,
      },
    },
  });
};

const resendVerificationEmail = (email: string) => {
  return callAPI<ResendVerificationEmailAction>({
    types: [
      ProfileAT.RESEND_VERIFICATION_EMAIL_REQUEST,
      ProfileAT.RESEND_VERIFICATION_EMAIL_SUCCESS,
      ProfileAT.RESEND_VERIFICATION_EMAIL_FAILURE,
    ],
    endpoint: API.account.resendVerificationEmail,
    method: 'POST',
    meta: {
      toast: {
        success: `Verification email sent to ${email}.`,
        error: true,
      },
    },
  });
};

const cancelEmailChange = () => {
  return callAPI<CancelEmailChangeAction>({
    types: [
      ProfileAT.CANCEL_EMAIL_CHANGE_REQUEST,
      ProfileAT.CANCEL_EMAIL_CHANGE_SUCCESS,
      ProfileAT.CANCEL_EMAIL_CHANGE_FAILURE,
    ],
    endpoint: API.account.cancelEmailChange,
    method: 'POST',
    schema: UserDetailsSchema,
    path: 'data',
    meta: {
      toast: {
        success: 'Email change cancelled',
        error: true,
      },
    },
  });
};

const coupons = {
  redeem: redeemCoupon,
  fetch: fetchUserCoupons,
};

const subscriptions = {
  fetch: fetchSubscriptions,
};

const account = {
  delete: deleteAccount,
};

const emailVerification = {
  resend: resendVerificationEmail,
  cancel: cancelEmailChange,
};

const payment = {
  update: updatePaymentSettings,
  fetch: fetchPaymentSettings,
};

export { emailVerification, account, payment, subscriptions, coupons };
