import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import uniq from 'lodash/uniq';

import isEmail from 'validator/lib/isEmail';

import { useIsUserLoading, useUser, useRoles, useTeams } from 'redux/selectors';
import { useWindowDimensions } from 'utils/hooks/useDimensions';
import { analytics } from 'utils';

import { organisationActions } from 'redux/actions/common';

import { Organisation } from 'types/common';

import { PERSONAS } from 'constants/settings';

import { Box, Flex, Text, MdIcon, useTheme, Button } from '@workshop/ui';
import { StepsModal } from 'components/Common';
import {
  LabelWrapper,
  LabelInput,
  ImageUpload,
  MultiSelect,
  LabelTextArea,
  LabelSelect,
} from 'components/Common';

interface CreateOrgPopupProps {
  isOpen: boolean;
  onClose: (confirm?: boolean) => void;
  reassignOwner?: boolean;
  title?: string;
  detailsLabel?: string;
  nameInputLabel?: string;
  nameInputHelpText?: string;
  nameInputPlaceholder?: string;
  nameInputErrorMessage?: string;
  inviteDescription?: string;
  inviteMessage?: string;
  inviteLandingPage?: string;
  invitePageButtonLabel?: string;
  onComplete?: (newTeamId: number) => void;
}

const CreateOrgPopup: React.FC<CreateOrgPopupProps> = ({
  isOpen,
  onClose,
  reassignOwner = false,
  title = 'Create a New Channel',
  detailsLabel = 'Channel Details',
  nameInputLabel = 'What do you want to call your channel?',
  nameInputHelpText = "(Don't worry, you can change this later)",
  nameInputPlaceholder,
  nameInputErrorMessage = 'Please enter a name for your channel',
  inviteDescription = 'Invite team members to help you make content and manage classes on your channel.',
  inviteMessage: defaultInviteMessage = '',
  inviteLandingPage,
  invitePageButtonLabel = 'See Invite Landing Page',
  onComplete,
}) => {
  const isLoading = useIsUserLoading();

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const user = useUser();
  const firstName = user?.name.split(' ')[0];

  const myTeams = useTeams();

  const [orgProfilePic, setOrgProfilePic] = useState('');
  const [orgName, setOrgName] = useState('');
  const [orgPersona, setOrgPersona] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [teamId, setTeamId] = useState<number | null>(null);
  const [inviteMessage, setInviteMessage] = useState(defaultInviteMessage);

  const allRoles = useRoles();
  const formattedRoles = Object.values(allRoles)
    .filter((r) => !!r.slug)
    .map((r) => ({
      id: r.id,
      value: r.slug,
      label: r.name,
    }));

  const memberTemplate = {
    name: '',
    email: '',
    roles: [],
  };
  const initialMember = {
    ...memberTemplate,
    roles: reassignOwner
      ? formattedRoles.filter((r) => r.value === 'owner')
      : [],
  };
  const [teamMembers, setTeamMembers] = useState([initialMember]);

  const filteredRoles = formattedRoles.filter(
    (r) => r.value !== 'admin' && r.value !== 'owner'
  );

  const dispatch = useDispatch();

  const orgFormMethods = useForm<Partial<Organisation>>({
    defaultValues: {
      logoDark: orgProfilePic,
      name: orgName,
      persona: orgPersona,
    },
  });

  const {
    register: orgRegister,
    handleSubmit: handleCreateOrgSubmit,
    errors: orgErrors,
    clearError: clearOrgError,
    setValue: setOrgValue,
    reset: resetOrgForm,
  } = orgFormMethods;

  const onImageDrop = (fieldName: string, acceptedFiles: File[]) => {
    setOrgValue(fieldName, acceptedFiles[0]);
    setOrgProfilePic(URL.createObjectURL(acceptedFiles[0]));
  };

  const onCreateOrgSubmit = handleCreateOrgSubmit(async (data) => {
    clearOrgError();
    setIsSubmitting(true);
    const response = await dispatch(
      organisationActions.createOrganisation({
        name: data.name,
        persona: data.persona || 'general',
        ...(data.logoDark ? { logoDark: data.logoDark } : {}),
      })
    );
    if (response.error) {
      setIsSubmitting(false);
      return;
    }
    if (data.logoDark) {
      analytics.track('Channel Profile Picture Uploaded');
    }
    const teamsRes = await dispatch(organisationActions.fetchMyTeams());
    const teams =
      teamsRes?.payload &&
      'results' in teamsRes.payload &&
      teamsRes.payload.results;
    const newTeam =
      teams && teams.length > 0
        ? [...teams].sort((a, b) => b.team - a.team)[0].team
        : null;
    if (newTeam) {
      // @ts-ignore
      dispatch(organisationActions.setCurrentTeam(newTeam));
      // @ts-ignore
      setTeamId(newTeam);
      localStorage.setItem('defaultTeam', newTeam.toString());
    }
    analytics.track('Channel Created', {
      ...(response.payload && 'id' in response.payload
        ? { org_id: `${response.payload.id}` }
        : {}),
    });
    analytics.logConversion('createdChannel');
    setIsSubmitting(false);
  });

  const onInviteSubmit = async () => {
    setIsSubmitting(true);

    if (reassignOwner) {
      // Switch current role from owner to team manager before inviting new team owner
      const teamManagerRole = Object.values(allRoles).find(
        (r) => r.slug === 'team_manager'
      );
      const myTeamMember = myTeams.find((t) => t.team === teamId);
      if (teamManagerRole && myTeamMember) {
        await dispatch(
          organisationActions.updateTeamMember(myTeamMember.id, {
            roles: [teamManagerRole.id],
          })
        );
      }
    }

    const inviteActions = teamMembers.map(({ name, email, roles }) => {
      const rolesList = roles.map(({ id }) => id);
      const permissions = uniq(
        rolesList.reduce((perms: number[], r) => {
          return [...perms, ...allRoles[r].permissions];
        }, [])
      );
      return dispatch(
        organisationActions.sendMemberInvitation({
          email,
          name,
          roles: rolesList,
          permissions,
          ...(inviteMessage ? { message: inviteMessage } : {}),
          ...(inviteLandingPage ? { redirect_url: inviteLandingPage } : {}),
        })
      );
    });

    await Promise.all(inviteActions);
    setIsSubmitting(false);
  };

  const onClosePopup = (confirm: boolean) => {
    resetOrgForm();
    onClose(confirm);
  };

  const steps = [
    {
      label: detailsLabel,
      icon: <MdIcon name="Store" />,
      nextButtonText: 'Create',
      nextButtonDisabled: !orgName,
      showSkipButton: false,
      content: (
        <Box py={4}>
          <FormContext {...orgFormMethods}>
            <Flex
              alignItems={{ base: 'initial', md: 'center' }}
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Flex
                backgroundColor="background.tint1"
                borderRadius="full"
                // borderWidth={1}
                // borderColor="border.default"
                width="100px"
                mr={{ base: 'auto', md: 4 }}
                ml={{ base: 'auto', md: 0 }}
                mb={{ base: 4, md: 0 }}
              >
                <ImageUpload
                  id="logoDark"
                  name="logoDark"
                  backgroundColor="transparent"
                  borderRadius="50px"
                  height="100px"
                  image={orgProfilePic}
                  onDrop={onImageDrop}
                  width="100%"
                  isDisabled={isSubmitting || isLoading}
                  hideText
                />
              </Flex>
              <Box flex={1}>
                <LabelInput
                  id="name"
                  name="name"
                  label={nameInputLabel}
                  helpText={nameInputHelpText}
                  placeholder={
                    nameInputPlaceholder ||
                    `${
                      firstName
                        ? firstName.charAt(firstName.length - 1) === 's'
                          ? `${firstName}'`
                          : `${firstName}'s`
                        : 'My'
                    } Channel`
                  }
                  labelPosition="top"
                  error={Boolean(orgErrors.name)}
                  errorMessage={nameInputErrorMessage}
                  registerInputRef={orgRegister({ required: true })}
                  value={orgName}
                  onChange={(e) => {
                    setOrgValue('name', e.target.value);
                    setOrgName(e.target.value);
                  }}
                  isDisabled={isSubmitting || isLoading}
                />
              </Box>
            </Flex>
            <Box mt={4}>
              <LabelSelect
                id="persona"
                name="persona"
                label="What kind of course creator are you?"
                labelPosition="top"
                color={orgPersona ? 'text.default' : 'text.placeholder'}
                isLoading={isLoading}
                registerInputRef={orgRegister()}
                options={PERSONAS}
                placeholder="Select the option that fits best..."
                isDisabled={isLoading || isSubmitting}
                unsorted
                onChange={(e) => {
                  setOrgValue('persona', e.target.value);
                  setOrgPersona(e.target.value);
                }}
              />
            </Box>
          </FormContext>
        </Box>
      ),
    },
  ];

  // Only allow invites if this org is being created
  // created by another org (i.e. schools)
  const canInvite = reassignOwner;

  if (canInvite) {
    steps.push({
      label: 'Invite Members',
      icon: <MdIcon name="GroupAdd" />,
      nextButtonText: 'Send Invites',
      nextButtonDisabled: !!teamMembers.find(
        ({ name, email, roles }) => !name || !isEmail(email) || !roles?.length
      ),
      showSkipButton: true,
      content: (
        <Box py={4}>
          <Text mb={4}>{inviteDescription}</Text>
          <LabelTextArea
            id="inviteMessage"
            name="inviteMessage"
            label="Message"
            placeholder="Send a message with your invites..."
            labelPosition="top"
            value={inviteMessage}
            onChange={(e) => setInviteMessage(e.target.value)}
            autoResize
            mb={inviteLandingPage ? 1 : 2}
            fontStyle="italic"
          />
          {inviteLandingPage ? (
            <Button
              secondary
              size="sm"
              icon="OpenInNew"
              onClick={() => window.open(inviteLandingPage, '_blank')}
              mb={4}
              width="100%"
            >
              {invitePageButtonLabel}
            </Button>
          ) : null}
          {teamMembers.map(({ name, email, roles }, index) => {
            return (
              <Flex
                key={`team-member-${index}`}
                borderRadius="md"
                background="background.tint3"
                padding="defaultPadding"
                flexDirection="column"
                mb={2}
                position="relative"
              >
                <LabelInput
                  id="name"
                  name="name"
                  label="Name"
                  placeholder={`Member #${index + 1} Name`}
                  labelPosition="top"
                  value={name}
                  onChange={(e) => {
                    const newTeamMembers = [...teamMembers].map((m, i) => ({
                      ...m,
                      ...(i === index
                        ? {
                            name: e.target.value,
                          }
                        : {}),
                    }));
                    setTeamMembers(newTeamMembers);
                  }}
                />
                <LabelInput
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="name@example.com"
                  labelPosition="top"
                  value={email}
                  onChange={(e) => {
                    const newTeamMembers = [...teamMembers].map((m, i) => ({
                      ...m,
                      ...(i === index
                        ? {
                            email: e.target.value,
                          }
                        : {}),
                    }));
                    setTeamMembers(newTeamMembers);
                  }}
                  isLoading={isLoading}
                />
                <LabelWrapper labelPosition="top" label="Roles">
                  <MultiSelect
                    placeholder="Select role(s)..."
                    options={filteredRoles}
                    value={roles}
                    onChange={(values) => {
                      const newTeamMembers = [...teamMembers].map((m, i) => ({
                        ...m,
                        ...(i === index
                          ? {
                              roles: values,
                            }
                          : {}),
                      }));
                      // @ts-ignore
                      setTeamMembers(newTeamMembers);
                    }}
                    isDisabled={Boolean(
                      !!roles.find((r) => r.value === 'owner')
                    )}
                  />
                </LabelWrapper>
                {index > 0 && (
                  <Box
                    position="absolute"
                    top={0}
                    right={0}
                    padding={2}
                    onClick={() => {
                      const newTeamMembers = teamMembers.filter(
                        (m, i) => i !== index
                      );
                      setTeamMembers(newTeamMembers);
                    }}
                    cursor="pointer"
                  >
                    <MdIcon name="RemoveCircle" color="icon.muted" />
                  </Box>
                )}
              </Flex>
            );
          })}

          <Button
            onClick={() => {
              const newTeamMembers = [...teamMembers, memberTemplate];
              setTeamMembers(newTeamMembers);
            }}
            variant="ghost"
            icon="Add"
            size="sm"
          >
            Add Member
          </Button>
        </Box>
      ),
    });
  }

  return (
    <StepsModal
      heading={title}
      isOpen={isOpen}
      onClose={() => onClosePopup(true)}
      onCompleteStep={async (stepIndex: number) => {
        if (stepIndex === 0) {
          try {
            await onCreateOrgSubmit();
          } catch {
            return 'error';
          }
          // If the popup doesn't allow invites, this is the final step
          if (!canInvite) {
            onClosePopup(false);
            onComplete && teamId && onComplete(teamId);
          }
        } else if (stepIndex === 1) {
          await onInviteSubmit();
          onClosePopup(false);
          onComplete && teamId && onComplete(teamId);
        }
      }}
      onSkipStep={(stepIndex: number) => {
        if (stepIndex === 1) {
          onClosePopup(false);
        }
      }}
      steps={steps}
      forceHorizontalSteps
      hideStepLabels={isMobile}
      disablePrev
    />
  );
};

export default CreateOrgPopup;
