import React from 'react';
import { Link } from 'react-router-dom';

import { PLATFORM_WEBSITE } from 'constants/common';

import navRoutes from 'navigation/Routes';

import { Flex, MdIcon, Text, Button, Box, Divider } from '@workshop/ui';

import { Loading } from 'components/Loading';
import { EditModal } from 'components/Common/EditModal';

interface ShareSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  courseSlug: string;
  channelHandle: string;
  isPublic: boolean;
  setIsPublic: (isPublic: boolean) => void;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  isLoading: boolean;
}

const ShareSessionModal: React.FC<ShareSessionModalProps> = ({
  isOpen,
  onClose,
  courseSlug,
  channelHandle,
  isPublic,
  setIsPublic,
  isVisible,
  setIsVisible,
  isLoading,
}) => {
  const relativePath =
    navRoutes.global.publicStandaloneSession.path(courseSlug);
  const path = `${window.location.host}${relativePath}`;
  return (
    <EditModal
      title="Share Your Session"
      isOpen={isOpen}
      onSave={() => null}
      onClose={onClose}
      onCancel={onClose}
      modalSize="xl"
      saveLabel=""
      cancelLabel="Done"
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Flex
            mb={4}
            alignItems={{ base: 'flex-start', sm: 'center' }}
            flexDirection={{ base: 'column', sm: 'row' }}
          >
            <Flex alignItems="center" flex={1} mb={{ base: 2, sm: 0 }}>
              <Text fontWeight="semibold" mr={3}>
                Session Availability:
              </Text>
              <MdIcon
                name={isPublic ? 'Public' : 'PublicOff'}
                color={isPublic ? 'icon.success' : 'icon.muted'}
                mr={1.5}
              />
              <Text
                fontWeight="semibold"
                color={isPublic ? 'text.success' : 'text.muted'}
                mr={2}
              >
                {isPublic ? 'Public' : 'Hidden'}
              </Text>
            </Flex>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsPublic(!isPublic)}
            >
              {isPublic ? 'Hide From Public' : 'Make Public'}
            </Button>
          </Flex>

          {isPublic && (
            <>
              <Text mb={2}>
                Here's the public link to your session, share it around!
              </Text>
              <Link to={relativePath}>
                <Box
                  mb={6}
                  borderRadius="md"
                  backgroundColor="background.success"
                  py={3}
                  px={4}
                  cursor="pointer"
                  _hover={{ opacity: 0.75 }}
                  // onClick={() => {
                  //   navigator.clipboard?.writeText(fullPath);
                  //   toast({
                  //     title: `Copied link to clipboard`,
                  //     status: 'info',
                  //     duration: 1500,
                  //   });
                  // }}
                  textAlign="center"
                >
                  <Text
                    color="text.success"
                    fontWeight="semibold"
                    fontSize="lg"
                  >
                    {path}
                  </Text>
                </Box>
              </Link>
              <Divider mt={6} mb={6} />
              <Box>
                <Flex
                  alignItems={{ base: 'flex-start', sm: 'center' }}
                  flexDirection={{ base: 'column', sm: 'row' }}
                >
                  <Flex alignItems="center" flex={1} mb={{ base: 2, sm: 0 }}>
                    <Text fontWeight="semibold" mr={3}>
                      Visibility:
                    </Text>
                    <MdIcon
                      name={isVisible ? 'Store' : 'VisibilityOff'}
                      color={isVisible ? 'icon.primary' : 'icon.muted'}
                      mr={1.5}
                    />
                    <Text
                      fontWeight="semibold"
                      color={isVisible ? 'text.primary' : 'text.muted'}
                      mr={2}
                    >
                      {isVisible ? 'On My Channel' : 'Unlisted'}
                    </Text>
                  </Flex>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    {isVisible ? 'Hide From Channel' : 'Show On Channel'}
                  </Button>
                </Flex>
                <Text fontSize="xs" color="text.muted">
                  {isVisible
                    ? `This session is accessible from ${`${PLATFORM_WEBSITE}/@${channelHandle}`}`
                    : 'This session is currently not visible on your channel'}
                </Text>
              </Box>
            </>
          )}
        </>
      )}
    </EditModal>
  );
};

export default ShareSessionModal;
